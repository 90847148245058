import React, { useRef } from 'react'
import Logo from '../../../assets/idcard/logo.png';
import Profile from '../../../assets/idcard/me.jpg';
import Registrar from '../../../assets/idcard/registrar.png';
import Principal from '../../../assets/idcard/principal.png';
import Header from '../../../assets/idcard/header.png';
import { handleSessionValidity } from '../../../configs/globalData';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetStudentByIdQuery } from '../../../services/StudentApi';
import Preloader from '../../../components/Preloader';
import useTitle from '../../../hooks/useTitle';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { BsDownload } from 'react-icons/bs';

const Fade = require("react-reveal/Fade");

export default function IDCard() {
          useTitle('আইডি কার্ড');

          const { id } = useParams<{ id: string }>();

          const navigate = useNavigate();

          const { data, isLoading } = useGetStudentByIdQuery({ id });

          const student = data?.data;

          const finalCard = useRef(null);

          if (isLoading) {
                    return <Preloader />
          }
          return (
                    <div className="p-2 bg-base-100 rounded-md pb-12">
                              <div className="title my-2 mb-5">
                                        <h3 className="text-xl font-semibold flex items-center gap-2"><HiOutlineArrowNarrowLeft size={20} className='cursor-pointer' onClick={() => navigate(-1)} /> আইডি কার্ড</h3>
                                        <span className='text-sm'>এখানে আইডি কার্ড দেখতে পারবেন</span>
                              </div>
                              <Fade top distance="20px">
                                        <div className='flex flex-col justify-center items-center w-full'>
                                                  <div className="card w-[375px] rounded-none pb-12">
                                                            <div ref={finalCard} className="card-body text-black bg-gradient-to-r from-[#fdf2e3] to-[#ecfdf3] border-[.5px]">
                                                                      <div className='w-full'>
                                                                                <figure><img src={Header} draggable={false} className="w-full select-none" alt="" /></figure>
                                                                      </div>
                                                                      <div className='flex justify-between items-center'>
                                                                                <figure><img src={Logo} draggable={false} className="select-none w-20" alt="" /></figure>
                                                                                <span className='p-1 text-2xl text-white font-bold rounded-xl bg-blue-900 select-none'>ID CARD</span>
                                                                                <figure><img src={student?.image || Profile} draggable={false} className="w-[6rem] h-[7.5rem] border-2 border-black" alt="" /></figure>
                                                                      </div>
                                                                      <div className='flex justify-between'>
                                                                                <h1 className='font-nimbusRomanBold text-center text-lg select-none'>Name</h1>
                                                                                <h1 className='font-nimbusRomanBold text-center text-lg uppercase select-none'>: {student?.fullName || 'toufiq hasan kiron'}</h1>
                                                                      </div>
                                                                      <h1 className='font-nimbusRomanRegular text-[#972158] text-md select-none capitalize'>Technology: {student?.technology || 'Computer'}</h1>
                                                                      <div className='flex justify-between'>
                                                                                <h1 className='font-nimbusRomanRegular text-md select-none'>ID/C - Roll: {student?.admissionRoll || '669152'}</h1>
                                                                                <h1 className='font-nimbusRomanRegular text-md select-none'>Board Roll: {student?.boardRoll || '416020'}</h1>
                                                                      </div>
                                                                      <div className='flex justify-between'>
                                                                                <h1 className='font-nimbusRomanRegular text-md select-none'>Reg No: {student?.registrationNumber || '1502045309'}</h1>
                                                                                <h1 className='font-nimbusRomanRegular text-md select-none'>Session: {student?.session || '2019-20'}</h1>
                                                                      </div>
                                                                      <div className='flex justify-between'>
                                                                                <h1 className='font-nimbusRomanRegular text-md select-none capitalize'>Shift: {student?.shift || 'First'}</h1>
                                                                                <h1 className='font-nimbusRomanRegular text-md select-none'>Validity: {student?.session ? handleSessionValidity(student?.session) : '31-07-2023'}</h1>
                                                                      </div>
                                                                      <p className='font-nimbusRomanRegular text-md select-none'>Blood Group: {student?.bloodGroup || 'B+'}</p>
                                                                      <div className='flex justify-between items-center gap-[200px]'>
                                                                                <p className='font-nimbusRomanRegular text-sm w-full flex flex-col justify-center items-center'>
                                                                                          <figure><img src={Registrar} draggable={false} className="w-8" alt="" /></figure>
                                                                                          <span className='select-none'>Registrar</span>
                                                                                </p>
                                                                                <p className='font-nimbusRomanRegular text-sm w-full flex flex-col justify-center items-center'>
                                                                                          <figure><img src={Principal} draggable={false} className="w-12" alt="" /></figure>
                                                                                          <span className='select-none'>Principal</span>
                                                                                </p>
                                                                      </div>
                                                            </div>
                                                  </div>
                                                  <button className='btn flex items-center gap-2'
                                                            onClick={() => exportComponentAsJPEG(
                                                                      finalCard,
                                                                      {
                                                                                fileName: `${student.fullName} - ${student.boardRoll}`,
                                                                      }
                                                            )}
                                                  >
                                                            <BsDownload /> <span>ডাউনলোড করুন</span>
                                                  </button>
                                        </div>
                              </Fade>
                    </div>
          )
}
