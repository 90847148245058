import {
          BaseQueryFn,
          createApi,
          FetchArgs,
          fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import Cookies from "universal-cookie";
import { BASE_API } from "../configs/config";
import { LoginAuthState, UserInterface } from "../interfaces/UserInterface";
const cookies = new Cookies();

type UserType = {
          _id?: number;
          role?: string;
          firstName: string;
          lastName: string;
          email: string;
          password?: string;
          profileImage?: string;
          isVerified: boolean;
          isOnline: boolean;
          isBlocked: boolean;
          createdAt?: string;
          updatedAt?: string;
};

type LoginType = {
          email?: string;
          password?: string;
          reCaptchaValue?: string;
};

type getUserType = {
          success: boolean;
          data: UserInterface;
};

type paginationType = {
          page: number;
          limit: number;
          role: string;
};

interface CustomError {
          data: {
                    message: string;
                    success: boolean;
          };
          status: number;
}

export const authApi = createApi({
          reducerPath: "authApi",
          baseQuery: fetchBaseQuery({
                    baseUrl: BASE_API,
                    prepareHeaders: (headers, api) => {
                              const cookie = cookies.get("user");
                              const token = cookie?.token;
                              if (token) {
                                        headers.set("authorization", `Bearer ${token}`);
                              }
                              return headers;
                    },
          }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
          tagTypes: ["GetUser"],
          endpoints: (builder) => ({
                    getOnlyUsers: builder.query({
                              query: () => "/users/only-users",
                              providesTags: ["GetUser"],
                    }),
                    getUserById: builder.query({
                              query: (id) => `/users/user-by-id/${id}`,
                              providesTags: ["GetUser"],
                    }),
                    getOnlyAdmins: builder.query({
                              query: () => "/users/only-admins",
                              providesTags: ["GetUser"],
                    }),
                    loginAuth: builder.mutation<LoginAuthState, LoginType>({
                              query: (body) => ({
                                        url: "/users/login",
                                        method: "POST",
                                        body,
                              }),
                              invalidatesTags: ["GetUser"],
                    }),
                    logOutAuth: builder.mutation({
                              query: (body) => ({
                                        url: "/users/logout",
                                        method: "POST",
                                        body,
                              }),
                              invalidatesTags: ["GetUser"],
                    }),
                    updateProfile: builder.mutation({
                              query: (body) => ({
                                        url: "/users/update-profile",
                                        method: "PATCH",
                                        body,
                              }),
                              invalidatesTags: ["GetUser"],
                    }),
                    changeProfilePicture: builder.mutation({
                              query: (body) => ({
                                        url: "/users/change-profile-picture",
                                        method: "POST",
                                        body,
                              }),
                              invalidatesTags: ["GetUser"],
                    }),
                    changeUserRole: builder.mutation({
                              query: (body) => ({
                                        url: "/users/change-user-role",
                                        method: "PATCH",
                                        body,
                              }),
                              invalidatesTags: ["GetUser"],
                    }),
                    getUser: builder.query<getUserType, string>({
                              query: (id) => `/users/me/${id}`,
                              providesTags: ["GetUser"],
                    }),
                    changePassword: builder.mutation({
                              query: (body) => ({
                                        url: "/users/change-password",
                                        method: "POST",
                                        body,
                              }),
                    }),
                    resetPassword: builder.mutation({
                              query: (body) => ({
                                        url: "/users/reset-password",
                                        method: "POST",
                                        body,
                              }),
                    }),
                    changePasswordWithoutOldPwd: builder.mutation({
                              query: (body) => ({
                                        url: "/users/change-password/new",
                                        method: "POST",
                                        body,
                              }),
                    }),
                    verificationMail: builder.mutation({
                              query: (body) => ({
                                        url: "/users/verify-mail",
                                        method: "POST",
                                        body,
                              }),
                    }),
                    changeUserStatus: builder.mutation({
                              query: (body) => ({
                                        url: "/users/change-user-status",
                                        method: "POST",
                                        body,
                              }),
                              invalidatesTags: ["GetUser"],
                    }),
                    getAllUsersForAdmin: builder.query<any, paginationType>({
                              query: (query: any) =>
                                        `/users/admin?role=${query.role}&limit=${query.limit}&page=${query.page}`,
                              providesTags: ["GetUser"],
                    }),
                    deleteUserForAdmin: builder.mutation({
                              query: (id: string) => ({
                                        url: `/users/admin/delete/${id}`,
                                        method: "DELETE",
                              }),
                              invalidatesTags: ["GetUser"],
                    }),
                    getAllUsers: builder.query({
                              query: () => "/admin/users",
                              providesTags: ["GetUser"],
                    }),
                    addAnAdmin: builder.mutation<UserType, UserType>({
                              query: (body) => ({
                                        url: `/users/add-an-admin`,
                                        method: "POST",
                                        body,
                              }),
                              invalidatesTags: ["GetUser"],
                    }),
          }),
});

export const {
          useLoginAuthMutation,
          useLogOutAuthMutation,
          useUpdateProfileMutation,
          useChangeProfilePictureMutation,
          useChangeUserRoleMutation,
          useGetUserQuery,
          useChangePasswordMutation,
          useResetPasswordMutation,
          useChangePasswordWithoutOldPwdMutation,
          useVerificationMailMutation,
          useGetOnlyUsersQuery,
          useGetOnlyAdminsQuery,
          useChangeUserStatusMutation,
          useGetAllUsersForAdminQuery,
          useDeleteUserForAdminMutation,
          useGetAllUsersQuery,
          useGetUserByIdQuery,
          useAddAnAdminMutation,
} = authApi;