import {
          BaseQueryFn,
          createApi,
          FetchArgs,
          fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import Cookies from "universal-cookie";
import { BASE_API } from "../configs/config";
const cookies = new Cookies();

type NoticeInfoType = {
          _id?: number;
          title?: string;
          url?: string;
          createdAt?: string;
          updatedAt?: string;
};

// type paginationType = {
//           page: number;
//           limit: number;
// };

interface CustomError {
          data: {
                    message: string;
                    success: boolean;
          };
          status: number;
}

export const noticeApi = createApi({
          reducerPath: "noticeApi",
          baseQuery: fetchBaseQuery({
                    baseUrl: BASE_API,
                    prepareHeaders: (headers, api) => {
                              const cookie = cookies.get("user");
                              const token = cookie?.token;
                              if (token) {
                                        headers.set("authorization", `Bearer ${token}`);
                              }
                              return headers;
                    },
          }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
          tagTypes: ["GetNoticeInfo"],
          endpoints: (builder) => ({
                    getAllNotices: builder.query({
                              query: () => "/notices",
                              providesTags: ["GetNoticeInfo"],
                    }),
                    getNoticeById: builder.query({
                              query: ({ id }) => `/notices/by-id?id=${id}`,
                              providesTags: ["GetNoticeInfo"],
                    }),
                    createNotice: builder.mutation<NoticeInfoType, NoticeInfoType>({
                              query: (body) => ({
                                        url: `/notices/create-notice`,
                                        method: "POST",
                                        body,
                              }),
                              invalidatesTags: ["GetNoticeInfo"],
                    }),
          }),
});

export const {
          useGetAllNoticesQuery,
          useGetNoticeByIdQuery,
          useCreateNoticeMutation,
} = noticeApi;