import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useGetNoticeByIdQuery } from '../../../services/NoticeApi';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import Loader from '../../../components/Loader';

const Fade = require("react-reveal/Fade");

export default function Notice() {
          const { id } = useParams<{ id: string }>();

          const navigate = useNavigate();

          const { data, isLoading } = useGetNoticeByIdQuery({ id });

          const notice = data?.notice;

          return (
                    <>
                              {
                                        isLoading ? <Loader /> : (
                                                  <div className="p-2 bg-base-100 rounded-md pb-12">
                                                            <div className="title my-2 mb-5">
                                                                      <h3 className="text-xl font-semibold flex items-center gap-2"><HiOutlineArrowNarrowLeft size={20} className='cursor-pointer' onClick={() => navigate(-1)} /> নোটিশ আপডেট করুন</h3>
                                                                      <span className='text-sm'>এখানে নোটিশ আপডেট করতে পারবেন</span>
                                                            </div>
                                                            <Fade top distance="20px">
                                                                      <div>
                                                                                <h1>{notice?.title}</h1>
                                                                      </div>
                                                            </Fade>
                                                  </div>
                                        )
                              }
                    </>
          )
}
