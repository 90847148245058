import React, { useState, useEffect } from 'react'
import { PulseLoader } from 'react-spinners'
import { useForm } from 'react-hook-form';
import useTitle from '../../../hooks/useTitle';
import { BiSolidPen } from 'react-icons/bi';
import { BsImage } from 'react-icons/bs';
import { CgLoadbarDoc } from 'react-icons/cg';
import { IoMdTrash } from 'react-icons/io';
import toast from 'react-hot-toast';
import { useCreateStudentMutation } from '../../../services/StudentApi';
import { useNavigate } from 'react-router-dom';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';

const Fade = require("react-reveal/Fade");

type FormData = {
          image: any;
          fullName: string;
          technology: string;
          session: string;
          admissionRoll: string;
          boardRoll: string;
          registrationNumber: string;
          shift: string;
          bloodGroup?: string;
};

export default function AddNewStudent() {
          useTitle('নতুন শিক্ষার্থী যুক্ত করুন');

          const navigate = useNavigate();

          const [createStudent, { data, isLoading, isSuccess, error }] =
                    useCreateStudentMutation();

          const [previewSource, setPreviewSource] = useState<string>("");

          const { register, handleSubmit, watch, reset } = useForm<FormData>({
                    defaultValues: {
                              image: null,
                              fullName: '',
                              technology: '',
                              session: '',
                              admissionRoll: '',
                              boardRoll: '',
                              registrationNumber: '',
                              shift: '',
                              bloodGroup: '',
                    },
          });

          const onSubmit = async (data: any) => {
                    if (data.image === undefined || data.image === '' || data.image === null) {
                              return toast.error('ছবি আপলোড করুন');
                    } else if (data.fullName === '') {
                              return toast.error('পুরো নাম দিন');
                    } else if (data.technology === '' || data.technology === 'টেকনোলজি সিলেক্ট করুন') {
                              return toast.error('টেকনোলজি সিলেক্ট করুন');
                    } else if (data.session === '' || data.session === 'সেশন সিলেক্ট করুন') {
                              return toast.error('সেশন সিলেক্ট করুন');
                    } else if (data.admissionRoll === '') {
                              return toast.error('এডমিশন রোল দিন');
                    } else if (data.admissionRoll.length !== 6) {
                              return toast.error('এডমিশন রোল সঠিক নয়');
                    } else if (data.boardRoll === '') {
                              return toast.error('বোর্ড রোল দিন');
                    } else if (data.boardRoll.length !== 6) {
                              return toast.error('বোর্ড রোল সঠিক নয়');
                    } else if (data.registrationNumber === '') {
                              return toast.error('রেজিস্ট্রেশন নাম্বার দিন');
                    } else if (data.registrationNumber.length !== 10) {
                              return toast.error('রেজিস্ট্রেশন নাম্বার সঠিক নয়');
                    } else if (data.shift === '' || data.shift === 'শিফট সিলেক্ট করুন') {
                              return toast.error('শিফট সিলেক্ট করুন');
                    } else {

                              if (data.bloodGroup === '' || data.bloodGroup === 'রক্তের গ্রুপ সিলেক্ট করুন') {
                                        data.bloodGroup = '';
                              }

                              const image = data.image[0];
                              const formData = new FormData();
                              formData.append('fullName', data.fullName);
                              formData.append('technology', data.technology);
                              formData.append('session', data.session);
                              formData.append('admissionRoll', data.admissionRoll);
                              formData.append('boardRoll', data.boardRoll);
                              formData.append('registrationNumber', data.registrationNumber);
                              formData.append('shift', data.shift);
                              formData.append('bloodGroup', data.bloodGroup);
                              formData.append('image', image);

                              await createStudent(formData as any);
                    }
          };

          watch((data, { type }) => {
                    if (data?.image?.length > 0) {
                              const reader = new FileReader();
                              reader?.readAsDataURL(data?.image[0] as Blob);
                              reader.onloadend = () => {
                                        setPreviewSource(reader.result as string);
                              };
                    }
          });

          useEffect(() => {
                    if (error) {
                              toast.error((error as any).data?.message);
                    }

                    if (isSuccess) {
                              toast.success((data as any).message);
                              setPreviewSource('');
                              reset();
                    }
          }, [isSuccess, error, data, reset]);

          return (
                    <div className="p-2 bg-base-100 rounded-md pb-12">
                              <div className="title my-2 mb-5">
                                        <h3 className="text-xl font-semibold flex items-center gap-2"><HiOutlineArrowNarrowLeft size={20} className='cursor-pointer' onClick={() => navigate(-1)} /> নতুন শিক্ষার্থী</h3>
                                        <span className='text-sm'>এখানে নতুন শিক্ষার্থী যুক্ত করতে পারবেন</span>
                              </div>
                              <Fade top distance="20px">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                  <div className="name border rounded p-3 relative mt-10 flex-1 w-full md:w-80">
                                                            <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                      <h3 className="text-xs font-poppins">
                                                                                ছবি আপলোড করুন <span className='text-red-600'>*</span>
                                                                      </h3>
                                                            </div>
                                                            <label
                                                                      htmlFor="choose-student-image"
                                                                      className={`input-group flex items-center my-2 border-2 w-full border-dotted ${!previewSource && 'h-80'} rounded-md mt-2 justify-center cursor-grabbing`}
                                                            >
                                                                      {previewSource ? (
                                                                                <div
                                                                                          className="w-full h-80 md:w-80 overflow-hidden mx-auto rounded-md"
                                                                                >
                                                                                          <img
                                                                                                    src={previewSource}
                                                                                                    alt="previewImage"
                                                                                                    className="w-full h-full object-cover rounded-md"
                                                                                          />
                                                                                </div>
                                                                      ) : (
                                                                                <div className="icon text-5xl">
                                                                                          <BsImage />
                                                                                </div>
                                                                      )}
                                                            </label>

                                                            <input
                                                                      type="file"
                                                                      accept="image/*"
                                                                      className="form-control outline-none pl-4 w-full hidden"
                                                                      id="choose-student-image"
                                                                      {...register("image")}
                                                            />

                                                            {
                                                                      previewSource && (
                                                                                <div className='flex justify-end mt-1'>
                                                                                          <IoMdTrash className='cursor-pointer bg-error text-white rounded-lg p-1'
                                                                                                    onClick={() => {
                                                                                                              setPreviewSource('')
                                                                                                              reset({
                                                                                                                        image: null
                                                                                                              })
                                                                                                    }}
                                                                                                    size={23}
                                                                                          />
                                                                                </div>
                                                                      )
                                                            }
                                                  </div>
                                                  <div className="name border rounded p-3 relative mt-10 flex-1">
                                                            <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                      <h3 className="text-xs font-poppins">পুরো নাম <span className='text-red-600'>*</span></h3>
                                                            </div>
                                                            <div className="flex items-center gap-3">
                                                                      <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                                <div className="icon">
                                                                                          <BiSolidPen />
                                                                                </div>
                                                                                <input
                                                                                          type="text"
                                                                                          className={`form-control outline-none pl-4 w-full placeholder:text-sm`}
                                                                                          placeholder="পুরো নাম"
                                                                                          {...register("fullName")}
                                                                                />
                                                                      </div>
                                                            </div>
                                                  </div>

                                                  <div className='flex flex-col md:flex-row justify-center items-center gap-2 w-full'>
                                                            <div className="name border rounded p-3 relative mt-10 w-full">
                                                                      <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">টেকনোলজি সিলেক্ট করুন <span className='text-red-600'>*</span></h3>
                                                                      </div>
                                                                      <div className='input-group flex items-center my-2 border p-3 rounded-md mt-2'>
                                                                                <div className="icon">
                                                                                          <CgLoadbarDoc />
                                                                                </div>
                                                                                <select
                                                                                          className="select outline-none w-full focus:outline-none"
                                                                                          {...register("technology")}
                                                                                >
                                                                                          <option selected value={""}>
                                                                                                    টেকনোলজি সিলেক্ট করুন
                                                                                          </option>
                                                                                          <option value={'civil'}>সিভিল টেকনোলজি</option>
                                                                                          <option value={'mechanical'}>মেকানিক্যাল টেকনোলজি</option>
                                                                                          <option value={'power'}>পাওয়ার টেকনোলজি</option>
                                                                                          <option value={'electrical'}>ইলেকট্রিক্যাল টেকনোলজি</option>
                                                                                          <option value={'electronics'}>ইলেকট্রনিক্স টেকনোলজি</option>
                                                                                          <option value={'computer'}>কম্পিউটার টেকনোলজি</option>
                                                                                          <option value={'rac'}>আর.এ.সি টেকনোলজি</option>
                                                                                          <option value={'mining'}>মাইনিং টেকনোলজি</option>
                                                                                          <option value={'tourism'}>ট্যুরিজম টেকনোলজি</option>
                                                                                          <option value={'rs'}>আর.এস টেকনোলজি</option>
                                                                                </select>
                                                                      </div>
                                                            </div>

                                                            <div className="name border rounded p-3 relative mt-10 w-full">
                                                                      <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">সেশন সিলেক্ট করুন <span className='text-red-600'>*</span></h3>
                                                                      </div>
                                                                      <div className="input-group flex items-center my-2 border p-3 rounded-md mt-2">
                                                                                <div className="icon">
                                                                                          <CgLoadbarDoc />
                                                                                </div>
                                                                                <select
                                                                                          className="select outline-none w-full focus:outline-none"
                                                                                          {...register("session")}
                                                                                >
                                                                                          <option selected value={""}>
                                                                                                    সেশন সিলেক্ট করুন
                                                                                          </option>
                                                                                          <option value={'2019-20'}>২০১৯-২০</option>
                                                                                          <option value={'2020-21'}>২০২০-২১</option>
                                                                                          <option value={'2021-22'}>২০২১-২২</option>
                                                                                </select>
                                                                      </div>
                                                            </div>
                                                  </div>

                                                  <div className='flex flex-col md:flex-row justify-center items-center gap-2 w-full'>
                                                            <div className="name border rounded p-3 relative mt-10 w-full">
                                                                      <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">এডমিশন রোল <span className='text-red-600'>*</span></h3>
                                                                      </div>
                                                                      <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                                <div className="icon">
                                                                                          <BiSolidPen />
                                                                                </div>
                                                                                <input
                                                                                          type="number"
                                                                                          className='form-control outline-none pl-4 w-full bg-transparent placeholder:text-sm'
                                                                                          placeholder="এডমিশন রোল"
                                                                                          {...register("admissionRoll")}
                                                                                />
                                                                      </div>
                                                            </div>
                                                            <div className="name border rounded p-3 relative mt-10 w-full">
                                                                      <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">বোর্ড রোল <span className='text-red-600'>*</span></h3>
                                                                      </div>
                                                                      <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                                <div className="icon">
                                                                                          <BiSolidPen />
                                                                                </div>
                                                                                <input
                                                                                          type="number"
                                                                                          className={`form-control outline-none pl-4 w-full bg-transparent placeholder:text-sm`}
                                                                                          placeholder="বোর্ড রোল"
                                                                                          {...register("boardRoll")}
                                                                                />

                                                                      </div>
                                                            </div>

                                                  </div>

                                                  <div className="name border rounded p-3 relative mt-10">
                                                            <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                      <h3 className="text-xs font-poppins">রেজিস্ট্রেশন নাম্বার <span className='text-red-600'>*</span></h3>
                                                            </div>
                                                            <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                      <div className="icon">
                                                                                <BiSolidPen />
                                                                      </div>
                                                                      <input
                                                                                type="number"
                                                                                className="form-control outline-none pl-4 w-full bg-transparent placeholder:text-sm"
                                                                                placeholder="রেজিস্ট্রেশন নাম্বার"
                                                                                {...register("registrationNumber")}
                                                                      />
                                                            </div>
                                                  </div>

                                                  <div className='flex flex-col md:flex-row justify-center items-center gap-2 w-full'>
                                                            <div className="name border rounded p-3 relative mt-10 w-full">
                                                                      <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">শিফট সিলেক্ট করুন <span className='text-red-600'>*</span></h3>
                                                                      </div>
                                                                      <div className="input-group flex items-center my-2 border p-3 rounded-md mt-2">
                                                                                <div className="icon">
                                                                                          <CgLoadbarDoc />
                                                                                </div>
                                                                                <select
                                                                                          className="select outline-none w-full focus:outline-none"
                                                                                          {...register("shift")}
                                                                                >
                                                                                          <option selected value={""}>
                                                                                                    শিফট সিলেক্ট করুন
                                                                                          </option>
                                                                                          <option value={'first'}>প্রথম শিফট</option>
                                                                                          <option value={'second'}>দ্বিতীয় শিফট</option>
                                                                                </select>
                                                                      </div>
                                                            </div>

                                                            <div className="name border rounded p-3 relative mt-10 w-full">
                                                                      <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">রক্তের গ্রুপ সিলেক্ট করুন</h3>
                                                                      </div>
                                                                      <div className="input-group flex items-center my-2 border p-3 rounded-md mt-2">
                                                                                <div className="icon">
                                                                                          <CgLoadbarDoc />
                                                                                </div>
                                                                                <select
                                                                                          className="select outline-none w-full focus:outline-none"
                                                                                          {...register("bloodGroup")}
                                                                                >
                                                                                          <option selected value={""}>রক্তের গ্রুপ সিলেক্ট করুন</option>
                                                                                          <option>A+</option>
                                                                                          <option>A-</option>
                                                                                          <option>B+</option>
                                                                                          <option>B-</option>
                                                                                          <option>O+</option>
                                                                                          <option>O-</option>
                                                                                          <option>AB+</option>
                                                                                          <option>AB-</option>
                                                                                </select>
                                                                      </div>
                                                            </div>
                                                  </div>

                                                  <div className='mt-10 flex justify-center md:justify-end'>
                                                            {isLoading ? (
                                                                      <button className="btn btn-sm md:btn-md bg-primary hover:bg-primary border-none" type="button">
                                                                                <PulseLoader size={8} color="#fff" />
                                                                      </button>
                                                            ) : (
                                                                      <button disabled={isLoading} className="btn btn-sm md:btn-md bg-primary hover:bg-primary border-none text-white" type="submit">
                                                                                সাবমিট করুন
                                                                      </button>
                                                            )}
                                                  </div>
                                        </form>
                              </Fade>
                    </div>
          )
}
