import React from 'react'
import GlobalStyle from '../../../shared/GlobalStyle/GlobalStyle'
import useTitle from '../../../hooks/useTitle'
import { teachers } from '../../../configs/globalData';
import { FaFacebook, FaSquareXTwitter, FaInstagram } from 'react-icons/fa6';

export default function CivilTeachers() {
          useTitle('শিক্ষক / কর্মকর্তাগণের তালিকা (সিভিল টেকনোলজি)')

          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                  <h1 className='pl-4'>শিক্ষক / কর্মকর্তাগণের তালিকা</h1>
                                        </div>
                                        <div className="overflow-x-auto pt-4">
                                                  <table className="min-w-full divide-y divide-black border border-black">
                                                            <thead>
                                                                      <tr>
                                                                                <th className="md:px-6 py-6 text-center md:text-left text-base leading-4 font-semibold uppercase tracking-wider w-1/12">
                                                                                          #
                                                                                </th>
                                                                                <th className="md:px-6 py-6 text-center md:text-left text-base leading-4 font-semibold uppercase tracking-wider w-11/12">
                                                                                          বিস্তারিত
                                                                                </th>
                                                                      </tr>
                                                            </thead>
                                                            <tbody>
                                                                      {
                                                                                teachers.map((teacher, index) => (
                                                                                          <tr key={teacher.id} className='border border-black'>
                                                                                                    <td className="px-2 md:px-6 py-6 whitespace-no-wrap text-sm md:text-base font-semibold">
                                                                                                              {index + 1}
                                                                                                    </td>
                                                                                                    <td className='px-2 md:px-6 text-sm md:text-base text-start border border-black py-6'>
                                                                                                              <div className='flex flex-col md:flex-row items-center md:justify-between justify-center gap-3 w-full'>
                                                                                                                        <div className="w-48 md:w-36 lg:w-40 rounded-xl ring ring-primary ring-offset-base-100 ring-offset-2">
                                                                                                                                  <img src={teacher.image} draggable={false} alt={teacher.name} className='aspect-square object-cover w-full rounded-xl' />
                                                                                                                        </div>
                                                                                                                        <div className='flex flex-col items-center gap-2 mt-4 md:mt-0'>
                                                                                                                                  <p className='text-base md:text-lg lg:text-xl font-bold text-center'>{teacher.name}</p>
                                                                                                                                  <p className='text-sm md:text-base pb-4'>{teacher.designation}</p>
                                                                                                                                  <p className='text-sm'><span className='font-semibold'>যোগদানের তারিখঃ </span>{teacher.joinedDate}</p>
                                                                                                                                  <p className='text-sm'><span className='font-semibold'>শিক্ষাগত যোগ্যতাঃ </span>{teacher.qualification}</p>
                                                                                                                        </div>
                                                                                                                        <div className='flex flex-col items-center md:items-start gap-2'>
                                                                                                                                  <p className='text-sm'><span className='font-semibold'>মোবাইলঃ </span><a href={`tel:${teacher.mobile}`}>{teacher.mobile}</a></p>
                                                                                                                                  <p className='text-sm'><span className='font-semibold'>ইমেইলঃ </span><a href={`mailto:${teacher.email}`}>{teacher.email}</a></p>
                                                                                                                                  {
                                                                                                                                            teacher.socials.length > 0 ? (
                                                                                                                                                      <div className='flex flex-row items-center gap-2'>
                                                                                                                                                                <p className='text-sm'><span className='font-semibold'>সামাজিক যোগাযোগঃ </span></p>
                                                                                                                                                                {
                                                                                                                                                                          teacher.socials.map((social, index) => (
                                                                                                                                                                                    <a href={social.url} key={index} target='_blank' rel='noreferrer'>
                                                                                                                                                                                              {
                                                                                                                                                                                                        social.title === 'Facebook' ? (
                                                                                                                                                                                                                  <FaFacebook size={20} />
                                                                                                                                                                                                        ) : social.title === 'Twitter' ? (
                                                                                                                                                                                                                  <FaSquareXTwitter size={20} />
                                                                                                                                                                                                        ) : social.title === 'Instagram' ? (
                                                                                                                                                                                                                  <FaInstagram size={20} />
                                                                                                                                                                                                        ) : null
                                                                                                                                                                                              }
                                                                                                                                                                                    </a>
                                                                                                                                                                          ))
                                                                                                                                                                }
                                                                                                                                                      </div>
                                                                                                                                            ) : (
                                                                                                                                                      <div className='flex flex-row items-center gap-2'>
                                                                                                                                                                <p className='text-sm'><span className='font-semibold'>সামাজিক যোগাযোগঃ N/A</span></p>
                                                                                                                                                      </div>
                                                                                                                                            )
                                                                                                                                  }
                                                                                                                        </div>
                                                                                                              </div>
                                                                                                    </td>
                                                                                          </tr>
                                                                                ))
                                                                      }
                                                            </tbody>
                                                  </table>
                                        </div>
                                        <div className='flex flex-col md:flex-row items-center md:justify-between justify-center pt-5 gap-3 w-full'>
                                                  <p className='text-lg font-bold'>Page 1 of 4 - Total - {teachers.length}</p>
                                                  <div className="join">
                                                            <button className="join-item btn btn-sm md:btn-md btn-active btn-outline">1</button>
                                                            <button className="join-item btn btn-sm md:btn-md btn-outline">2</button>
                                                            <button className="join-item btn btn-sm md:btn-md btn-outline">3</button>
                                                            <button className="join-item btn btn-sm md:btn-md btn-outline">4</button>
                                                  </div>
                                        </div>
                                        <p className='pt-10 text-sm'>সর্বশেষ হাল-নাগাদঃ 13 Oct 2023</p>
                              </div>
                    </GlobalStyle>
          )
}
