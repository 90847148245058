import React from 'react'
import Image from '../assets/404.png';
import { HiArrowLongLeft } from 'react-icons/hi2';
import useTitle from '../hooks/useTitle'
import { Link } from 'react-router-dom';

export default function NotFound() {
          useTitle('পেইজ খুঁজে পাওয়া যায়নি');

          return (
                    <div className='flex flex-col justify-center items-center h-screen gap-5'>
                              <img src={Image} draggable={false} alt="404" className='h-auto object-cover w-72 md:w-80' />
                              <Link to={'/'} className='flex items-center gap-2 bg-primary text-white px-3 py-2 rounded-md'><HiArrowLongLeft size={25} />Go Back</Link>
                    </div>
          )
}
