import React from 'react'
import useAuth from '../../../hooks/useAuth';
import { authUserInterface } from '../../../interfaces/UserInterface';
import useTitle from '../../../hooks/useTitle';

export default function Index() {
          useTitle('ড্যাশবোর্ড');
          const {
                    updatedUser: data,
          } = useAuth<authUserInterface | any>({});

          return (
                    <div className="flex justify-center items-center flex-col h-[70vh] gap-5">
                              <div className="avatar md:mb-10">
                                        <div className="w-48 mask mask-hexagon">
                                                  <img src={data.profileImage as string} alt="" />
                                        </div>
                              </div>
                              <h2 className={`text-xl md:text-2xl lg:text-3xl text-center lg:flex lg:gap-2 text-black`}>
                                        ড্যাশবোর্ড এ আপনাকে স্বাগতম,{" "}
                                        <span className={`block lg:flex font-semibold text-primary`}>
                                                  {data?.firstName} {data?.lastName}
                                        </span>
                              </h2>
                    </div>
          );
};

