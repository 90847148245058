import React from 'react'
import { NavLink, Outlet, Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';
import { MdOutlineSpaceDashboard, MdSpaceDashboard } from "react-icons/md";
import LOGO from "../../assets/logo.png";
import Avatar from "../../assets/logo.png";
import Swal from "sweetalert2";
import useTitle from '../../hooks/useTitle';
import { useLogOutAuthMutation } from '../../services/AuthApi';
import { useAppDispatch } from '../../app/store';
import { authUserInterface } from '../../interfaces/UserInterface';
import useAuth from '../../hooks/useAuth';
import { logout } from '../../features/AuthSlice';
import Preloader from '../../components/Preloader';
import { BiCog, BiLogOutCircle } from 'react-icons/bi';
import { HiOutlineHandRaised } from 'react-icons/hi2';
import { RiProfileLine } from 'react-icons/ri';
import { AiOutlineProfile } from 'react-icons/ai';
import { FaUsers } from 'react-icons/fa';
import { IoImagesOutline } from 'react-icons/io5';
import { GiDiploma } from 'react-icons/gi';

export default function Dashboard() {
          useTitle('ড্যাশবোর্ড');

          const [logOut] = useLogOutAuthMutation();
          const dispatch = useAppDispatch();
          const navigate = useNavigate();

          const {
                    user,
                    setUser,
                    updatedUser: data,
                    isLoading,
          } = useAuth<authUserInterface | any>({});

          const role = data?.role;

          const handleLogOut = (id: string) => {
                    Swal.fire({
                              title: "আপনি কি নিশ্চিত?",
                              text: "আপনি এখন এই অ্যাকাউন্ট থেকে লগ আউট হয়ে যাচ্ছেন।",
                              icon: "warning",
                              background: "#ffffff",
                              color: "#000000",
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              cancelButtonText: "না, বাদ দিন",
                              confirmButtonText: "হ্যাঁ, লগ আউট করুন",
                    }).then(async (result: any) => {
                              if (result.isConfirmed) {
                                        try {
                                                  await logOut({ id });
                                                  dispatch(logout());
                                                  setUser(null);
                                                  toast.success('লগ আউট সফল হয়েছে');
                                                  navigate('/');
                                        } catch (error) {
                                                  toast.error('লগ আউট সফল হয়নি');
                                        }
                              }
                    })
          };

          if (isLoading) {
                    return <Preloader />;
          }

          return (
                    <div className="drawer lg:drawer-open">
                              <input id="dashboard-sidebar" type="checkbox" className="drawer-toggle" />
                              <div className="drawer-content p-3">
                                        <div className={`header bg-gradient-to-bl from-[#fdf2e3] to-[#ecfdf3] border border-[#fdf2e3] sticky z-50 top-3 flex justify-between items-center shadow-lg p-4 rounded-xl`}>
                                                  <label
                                                            htmlFor="dashboard-sidebar"
                                                            className={`btn btn-outline duration-500 drawer-button group lg:hidden text-black border-black`}
                                                  >
                                                            <MdSpaceDashboard size={24} className={`duration-500 text-black group-hover:text-white`} />
                                                  </label>
                                                  <div className={`flex items-center gap-1 text-black`}>
                                                            <h1 className="text-lg md:text-lg lg:text-xl font-semibold hidden md:flex">
                                                                      হ্যালো, {data?.firstName} {data?.lastName} {" "}
                                                            </h1>
                                                            <Link
                                                                      to="/auth/dashboard"
                                                                      className={`text-lg md:text-lg lg:text-xl font-semibold text-primary text-center`}
                                                            >
                                                                      বি.পি.আই
                                                            </Link>
                                                            <div
                                                                      className={`text-lg md:text-lg lg:text-xl hidden md:flex font-semibold text-center`}
                                                            >
                                                                      এডমিন ড্যাশবোর্ডে আপনাকে স্বাগতম
                                                            </div>
                                                  </div>
                                                  <div className='hidden md:flex absolute right-20 cursor-pointer'>

                                                  </div>
                                                  <div className="dropdown dropdown-end">
                                                            <label
                                                                      tabIndex={0}
                                                                      className="btn btn-ghost btn-circle avatar online"
                                                            >
                                                                      <div
                                                                                style={{ display: "grid" }}
                                                                                className={`w-10 h-10 place-items-center rounded-full ring ring-primary ring-offset-base-100 ring-offset-2`}
                                                                      >
                                                                                <img
                                                                                          src={data.profileImage as string || Avatar}
                                                                                          alt={data.firstName || ""}
                                                                                />
                                                                      </div>
                                                            </label>
                                                            <ul
                                                                      tabIndex={0}
                                                                      className={`mt-6 -mr-4 p-2 shadow-xl menu menu-compact dropdown-content rounded-box w-80 relative border border-[#fdf2e3] bg-gradient-to-bl from-[#fdf2e3] to-[#ecfdf3]`}
                                                            >
                                                                      <div className='md:hidden absolute right-3 cursor-pointer'>

                                                                      </div>
                                                                      <div className={`w-16 h-16 rounded-full flex items-center justify-center mx-auto my-4 ring ring-primary ring-offset-base-100 ring-offset-2`}>
                                                                                <img
                                                                                          src={data.profileImage as string || Avatar}
                                                                                          alt={data.firstName || ""}
                                                                                          className='rounded-full'
                                                                                />
                                                                      </div>
                                                                      <div className={`text-center mb-4 text-black`}>
                                                                                <span className="font-semibold">হ্যালো,</span>
                                                                                <span className="flex justify-center items-center gap-1 font-semibold">
                                                                                          <h2 className={`text-primary`}>
                                                                                                    {data?.firstName} {data?.lastName}
                                                                                          </h2>
                                                                                          <HiOutlineHandRaised />
                                                                                </span>
                                                                                <div className="flex flex-col items-center gap-1 pt-2">
                                                                                          <h1 className="font-semibold">
                                                                                                    স্বাগতম,{" "}
                                                                                                    <span className={`font-semibold text-primary`}>
                                                                                                              <Link to="/">বগুড়া পলিটেকনিক ইন্সটিটিউট</Link>
                                                                                                    </span>
                                                                                          </h1>
                                                                                          <h1 className="font-semibold">
                                                                                                    (এডমিন প্যানেল)
                                                                                          </h1>
                                                                                </div>
                                                                      </div>
                                                                      <hr className={`font-semibold text-black`} />
                                                                      <li className={`py-1 text-black`}>
                                                                                <Link to={'/auth/dashboard/profile'}>
                                                                                          <RiProfileLine size={18} />
                                                                                          প্রোফাইল
                                                                                </Link>
                                                                      </li>
                                                                      <li className={`py-1 text-black`}>
                                                                                <button onClick={() => handleLogOut(user?.user?._id)}>
                                                                                          <BiLogOutCircle size={18} />
                                                                                          লগ আউট
                                                                                </button>
                                                                      </li>
                                                            </ul>
                                                  </div>
                                        </div>
                                        <Outlet />
                              </div>
                              <div className="drawer-side shadow-xl z-50">
                                        <label htmlFor="dashboard-sidebar" className="drawer-overlay"></label>
                                        <ul className={`menu p-4 overflow-y-auto h-full w-80 bg-gradient-to-br from-[#fdf2e3] to-[#ecfdf3] border-r border-[#fdf2e3] text-base-content rounded-tr-3xl rounded-br-3xl`}>
                                                  <div className={`flex flex-col items-center gap-3 text-2xl p-2 border-b border-black pb-5`}>
                                                            <Link
                                                                      to="/"
                                                                      className={`font-semibold text-base md:text-lg text-center flex items-center flex-col gap-2 text-black`}
                                                            >
                                                                      <img src={LOGO} alt="Logo" className="w-16 rounded-lg" /> বগুড়া পলিটেকনিক ইন্সটিটিউট
                                                                      <small className={`text-xs text-black font-normal`}>
                                                                                (এডমিন প্যানেল)
                                                                      </small>
                                                            </Link>
                                                  </div>
                                                  <li className="py-1 mt-3">
                                                            <NavLink
                                                                      className={({ isActive }) =>
                                                                                isActive ? "text-white bg-primary py-3 hover:bg-primary hover:text-white" : `text-black py-3 hover:bg-gray-600 hover:text-white`
                                                                      }
                                                                      to="/auth/dashboard"
                                                            >
                                                                      <MdOutlineSpaceDashboard size={18} /> ড্যাশবোর্ড
                                                            </NavLink>
                                                  </li>
                                                  {
                                                            role === 'admin' && (
                                                                      <>
                                                                                <li className="py-1">
                                                                                          <NavLink
                                                                                                    className={({ isActive }) =>
                                                                                                              isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `text-black py-3 hover:bg-gray-600 hover:text-white`
                                                                                                    }
                                                                                                    to="/auth/dashboard/all-students"
                                                                                          >
                                                                                                    <FaUsers size={18} /> সকল শিক্ষার্থী
                                                                                          </NavLink>
                                                                                </li>
                                                                                <li className="py-1">
                                                                                          <NavLink
                                                                                                    className={({ isActive }) =>
                                                                                                              isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `text-black py-3 hover:bg-gray-600 hover:text-white`
                                                                                                    }
                                                                                                    to="/auth/dashboard/all-teachers"
                                                                                          >
                                                                                                    <FaUsers size={18} /> সকল শিক্ষক
                                                                                          </NavLink>
                                                                                </li>
                                                                                <li className="py-1">
                                                                                          <NavLink
                                                                                                    className={({ isActive }) =>
                                                                                                              isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `text-black py-3 hover:bg-gray-600 hover:text-white`
                                                                                                    }
                                                                                                    to="/auth/dashboard/all-notices"
                                                                                          >
                                                                                                    <AiOutlineProfile size={18} /> নোটিশ
                                                                                          </NavLink>
                                                                                </li>
                                                                                <li className="py-1">
                                                                                          <NavLink
                                                                                                    className={({ isActive }) =>
                                                                                                              isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `text-black py-3 hover:bg-gray-600 hover:text-white`
                                                                                                    }
                                                                                                    to="/auth/dashboard/academic"
                                                                                          >
                                                                                                    <AiOutlineProfile size={18} /> একাডেমিক
                                                                                          </NavLink>
                                                                                </li>
                                                                                <li className="py-1">
                                                                                          <NavLink
                                                                                                    className={({ isActive }) =>
                                                                                                              isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `text-black py-3 hover:bg-gray-600 hover:text-white`
                                                                                                    }
                                                                                                    to="/auth/dashboard/gallery"
                                                                                          >
                                                                                                    <IoImagesOutline size={18} /> গ্যালারি
                                                                                          </NavLink>
                                                                                </li>
                                                                                <li className="py-1">
                                                                                          <NavLink
                                                                                                    className={({ isActive }) =>
                                                                                                              isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `text-black py-3 hover:bg-gray-600 hover:text-white`
                                                                                                    }
                                                                                                    to="/auth/dashboard/results"
                                                                                          >
                                                                                                    <GiDiploma size={18} /> ফলাফল
                                                                                          </NavLink>
                                                                                </li>
                                                                                <li className="py-1">
                                                                                          <NavLink
                                                                                                    className={({ isActive }) =>
                                                                                                              isActive ? "text-white bg-gray-600 py-3 hover:bg-gray-600 hover:text-white" : `text-black py-3 hover:bg-gray-600 hover:text-white`
                                                                                                    }
                                                                                                    to="/auth/dashboard/setting"
                                                                                          >
                                                                                                    <BiCog size={18} /> সেটিংস
                                                                                          </NavLink>
                                                                                </li>
                                                                      </>
                                                            )
                                                  }
                                                  <li className="absolute bottom-5 w-72">
                                                            <button
                                                                      onClick={() => handleLogOut(user?.user?._id)}
                                                                      className={`bg-transparent hover:bg-gray-600 hover:border-gray-600 border rounded-lg py-4 text-black border-black hover:text-white duration-300`}
                                                            >
                                                                      <BiLogOutCircle size={18} /> লগ আউট
                                                            </button>
                                                  </li>
                                        </ul>
                              </div>
                    </div>
          )
}
