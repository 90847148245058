import React, { useState, useEffect } from 'react'
import useTitle from '../../../hooks/useTitle'
import { AiOutlineLock } from 'react-icons/ai'
import toast from 'react-hot-toast';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useChangePasswordMutation } from '../../../services/AuthApi';
import useAuth from '../../../hooks/useAuth';
import { authUserInterface } from '../../../interfaces/UserInterface';
import { useAppDispatch } from '../../../app/store';
import { logout } from '../../../features/AuthSlice';
import { useNavigate } from 'react-router-dom';
import DashboardTitle from '../../../components/DashboardTitle';

export default function Setting() {
          useTitle('সেটিংস');

          const { updatedUser, setUser } = useAuth<authUserInterface | any>({});
          const [changePassword, { data, isSuccess, error }] =
                    useChangePasswordMutation();
          const dispatch = useAppDispatch();

          const navigate = useNavigate();

          const [isShow, setIsShow] = useState<boolean>(false);

          const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    const formData = new FormData(e.currentTarget);
                    const oldPassword = formData.get('oldPassword');
                    const newPassword = formData.get('newPassword');
                    const confirmNewPassword = formData.get('confirmNewPassword');
                    if (!oldPassword) return toast.error('Old Password is required');
                    if (!newPassword) return toast.error('New Password is required');
                    if (!confirmNewPassword) return toast.error('Confirm New Password is required');
                    if (newPassword !== confirmNewPassword) return toast.error('New Password and Confirm New Password must be same');
                    if (oldPassword && newPassword && confirmNewPassword) {
                              changePassword({
                                        email: updatedUser?.email,
                                        oldPassword,
                                        newPassword,
                              })
                    }
          }

          useEffect(() => {
                    if (error) toast.error((error as any)?.data?.message);
                    if (isSuccess) {
                              toast.success(
                                        data?.message + " Now you may login using the new password"
                              );
                              setUser(null);
                              dispatch(logout());
                              navigate("/");
                    }
          }, [isSuccess, data, error, dispatch, setUser, navigate]);

          return (
                    // <div className="lg:px-5 py-0 md:pb-5 lg:py-5 rounded-md">
                    //           <div className="title my-2 md:mb-5">
                    //                     <h3 className="text-2xl font-bold">সেটিংস</h3>
                    //           </div>

                    // </div>
                    <DashboardTitle title='সেটিংস' subtitle='এখানে আপনি আপনার পাসওয়ার্ড পরিবর্তন করতে পারবেন'>
                              <h3 className="font-semibold mb-2">পাসওয়ার্ড পরিবর্তন</h3>
                              <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
                                        <div className="flex -mx-3">
                                                  <div className="w-full px-3">
                                                            <label htmlFor="" className="text-xs font-semibold px-1">পুরোনো পাসওয়ার্ড দিন <span className='text-red-500'>*</span></label>
                                                            <div className="flex relative">
                                                                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><AiOutlineLock color='black' /></div>
                                                                      <input type={isShow ? 'text' : 'password'} name='oldPassword' className="w-full -ml-10 pl-10 pr-3 py-3 md:py-5 rounded-lg border-2 border-globalColor outline-none focus:border-primary text-black placeholder:text-sm" placeholder="পুরোনো পাসওয়ার্ড দিন" />
                                                                      <div
                                                                                className="eye absolute right-5 md:right-7 top-5 md:top-7 cursor-pointer z-10 select-none"
                                                                                onClick={() => setIsShow((state) => !state)}
                                                                      >
                                                                                {isShow ? <BsEyeSlash color='black' /> : <BsEye color='black' />}
                                                                      </div>
                                                            </div>
                                                  </div>
                                        </div>
                                        <div className="flex -mx-3">
                                                  <div className="w-full px-3">
                                                            <label htmlFor="" className="text-xs font-semibold px-1">নতুন পাসওয়ার্ড দিন <span className='text-red-500'>*</span></label>
                                                            <div className="flex">
                                                                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><AiOutlineLock color='black' /></div>
                                                                      <input type={isShow ? 'text' : 'password'} name='newPassword' className="w-full -ml-10 pl-10 pr-3 py-3 md:py-5 rounded-lg border-2 border-globalColor outline-none focus:border-primary text-black placeholder:text-sm" placeholder="নতুন পাসওয়ার্ড দিন" />
                                                            </div>
                                                  </div>
                                        </div>
                                        <div className="flex -mx-3">
                                                  <div className="w-full px-3">
                                                            <label htmlFor="" className="text-xs font-semibold px-1">নতুন পাসওয়ার্ড নিশ্চিত করুন <span className='text-red-500'>*</span></label>
                                                            <div className="flex">
                                                                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><AiOutlineLock color='black' /></div>
                                                                      <input type={isShow ? 'text' : 'password'} name='confirmNewPassword' className="w-full -ml-10 pl-10 pr-3 py-3 md:py-5 rounded-lg border-2 border-globalColor outline-none focus:border-primary text-black placeholder:text-sm" placeholder="নতুন পাসওয়ার্ড নিশ্চিত করুন" />
                                                            </div>
                                                  </div>
                                        </div>
                                        <div className='flex mx-auto'>
                                                  <button className="btn btn-sm md:btn-md bg-primary hover:bg-primary mt-2 md:mt-5 text-white">পরিবর্তন করুন</button>
                                        </div>
                              </form>
                    </DashboardTitle>
          )
}