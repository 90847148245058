import React from 'react'
import { createBrowserRouter } from 'react-router-dom';
import Root from './Layouts/Root';
import ShortIntro from './pages/AboutUs/Introduction/ShortIntro/ShortIntro';
import NotFound from './shared/NotFound';
import ContactUs from './pages/ContactUs/ContactUs';
import Notices from './pages/Notices/Notices';
import SingleNotice from './pages/Notices/SingleNotice';
import AimsAndObjectives from './pages/AboutUs/Introduction/AimsAndObjectives/AimsAndObjectives';
import PrincipalMessage from './pages/AboutUs/Introduction/PrincipalMessage/PrincipalMessage';
import VicePrincipal from './pages/AboutUs/Chiefs/VicePrincipal/VicePrincipal';
import FormerPrincipals from './pages/AboutUs/Chiefs/FormerPrincipals/FormerPrincipals';
import ClassRoutine from './pages/Academic/ClassRoutine/ClassRoutine';
import ExamRoutine from './pages/Academic/ExamRoutine/ExamRoutine';
import Results from './pages/ExamResults/Results/Results';
import Gallery from './pages/Gallery/Gallery';
import StaffList from './pages/AboutUs/Employees/StaffList/StaffList';
import Teachers from './pages/AboutUs/Employees/Teachers/Teachers';
import CivilTeachers from './pages/AllTechnology/CivilTeachers/CivilTeachers';
import ComputerTeachers from './pages/AllTechnology/ComputerTeachers/ComputerTeachers';
import Login from './pages/Auth/Login';
import AuthChangeRoute from './auth/AuthChangeRoute';
import Welcome from './pages/Dashboard/Welcome/Welcome';
import Dashboard from './pages/Dashboard/Dashboard';
import RequireAdmin from './auth/RequireAdmin';
import Setting from './pages/Dashboard/Setting/Setting';
import AllStudents from './pages/Dashboard/AllStudents/AllStudents';
import Profile from './pages/Dashboard/Profile/Profile';
import AddNewStudent from './pages/Dashboard/AllStudents/AddNewStudent';
import StudentInfo from './pages/StudentInfo/StudentInfo';
import SingleStudent from './pages/Dashboard/AllStudents/SingleStudent';
import IDCard from './pages/Dashboard/AllStudents/IDCard';
import AllNotices from './pages/Dashboard/AllNotices/AllNotices';
import AddNewNotice from './pages/Dashboard/AllNotices/AddNewNotice';
import Notice from './pages/Dashboard/AllNotices/Notice';

export const router = createBrowserRouter([
          {
                    path: '/',
                    element: <Root />
          },
          {
                    path: '/notices',
                    element: <Notices />
          },
          {
                    path: '/notices/:id',
                    element: <SingleNotice />
          },
          {
                    path: '/short-intro',
                    element: <ShortIntro />
          },
          {
                    path: '/aims-and-objectives',
                    element: <AimsAndObjectives />
          },
          {
                    path: '/principal-message',
                    element: <PrincipalMessage />
          },
          {
                    path: '/principal',
                    element: <PrincipalMessage />
          },
          {
                    path: '/vice-principal',
                    element: <VicePrincipal />
          },
          {
                    path: '/former-principals',
                    element: <FormerPrincipals />
          },
          {
                    path: '/teachers',
                    element: <Teachers />
          },
          {
                    path: '/teachers/civil',
                    element: <CivilTeachers />
          },
          {
                    path: '/teachers/computer',
                    element: <ComputerTeachers />
          },
          {
                    path: '/staffs-list',
                    element: <StaffList />
          },
          {
                    path: '/class-routine',
                    element: <ClassRoutine />
          },
          {
                    path: '/exam-routine',
                    element: <ExamRoutine />
          },
          {
                    path: '/results',
                    element: <Results />
          },
          {
                    path: '/gallery',
                    element: <Gallery />
          },
          {
                    path: '/student-info',
                    element: <StudentInfo />
          },
          {
                    path: '/contact-us',
                    element: <ContactUs />
          },
          {
                    path: '/auth/login',
                    element: <AuthChangeRoute>
                              <Login />
                    </AuthChangeRoute>
          },
          {
                    path: '/auth/dashboard',
                    element: <RequireAdmin>
                              <Dashboard />
                    </RequireAdmin>,
                    children: [
                              {
                                        path: '/auth/dashboard',
                                        element: <Welcome />
                              },
                              {
                                        path: '/auth/dashboard/profile',
                                        element: <Profile />
                              },
                              {
                                        path: '/auth/dashboard/all-students',
                                        element: <AllStudents />
                              },
                              {
                                        path: '/auth/dashboard/all-students/:id',
                                        element: <SingleStudent />
                              },
                              {
                                        path: '/auth/dashboard/all-students/id-card/:id',
                                        element: <IDCard />
                              },
                              {
                                        path: '/auth/dashboard/all-students/add-new',
                                        element: <AddNewStudent />
                              },
                              {
                                        path: '/auth/dashboard/all-notices',
                                        element: <AllNotices />
                              },
                              {
                                        path: '/auth/dashboard/all-notices/:id',
                                        element: <Notice />
                              },
                              {
                                        path: '/auth/dashboard/all-notices/add-new',
                                        element: <AddNewNotice />
                              },
                              {
                                        path: '/auth/dashboard/setting',
                                        element: <Setting />
                              },
                              {
                                        path: '*',
                                        element: <NotFound />
                              }
                    ]
          },
          {
                    path: '*',
                    element: <NotFound />
          }
]);
