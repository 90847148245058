import { format } from "date-fns";
import formatDistance from "date-fns/formatDistance";
import { BiCamera } from "react-icons/bi";
import { TbEdit } from "react-icons/tb";
import { MdVerified } from 'react-icons/md';
import { LazyLoadImage } from "react-lazy-load-image-component";
import useAuth from "../../../hooks/useAuth";
import useTitle from "../../../hooks/useTitle";
import { authUserInterface } from "../../../interfaces/UserInterface";
import ImageChangeModal from "./ImageChangeModal";
import ProfileModal from "./ProfileModal";
import Preloader from "../../../components/Preloader";

const Profile = () => {
          const {
                    user,
                    updatedUser: data,
                    refetch,
                    isLoading,
          } = useAuth<authUserInterface | any>({});

          const name = data?.firstName + " " + data?.lastName;

          useTitle(name + "'s প্রোফাইল");

          const dateDistance = formatDistance(
                    new Date(),
                    new Date(user?.user?.createdAt),
                    {
                              addSuffix: true,
                    }
          );

          if (isLoading) {
                    return <Preloader />;
          }

          const result = format(new Date(user?.user?.updatedAt), "PPPP BBBB ppp");

          return (
                    <>
                              <div className="profile p-5 my-5 bg-white">
                                        <div className="flex items-center gap-2">
                                                  <h2 className="text-xl md:text-2xl font-bold">
                                                            <span className="text-primary">
                                                                      {data?.firstName || "N/A"}'s
                                                            </span>{" "}
                                                            প্রোফাইল{" "}
                                                  </h2>
                                                  {
                                                            data?.role === 'admin' && (
                                                                      <small className="badge badge-primary select-none text-white uppercase font-bold">Admin</small>
                                                            )
                                                  }
                                        </div>
                                        <div className="profile-content py-10">
                                                  <div className="flex items-center justify-between">
                                                            <div className="profile-image rounded-full w-32 h-32 relative">
                                                                      <LazyLoadImage
                                                                                src={data?.profileImage ? data?.profileImage : data?.avatar}
                                                                                alt={data?.name}
                                                                                effect="black-and-white"
                                                                                className="w-32 h-32 rounded-full border-4 border-primary object-cover shadow-lg"
                                                                      />

                                                                      <label
                                                                                htmlFor="profile-image-edit-modal"
                                                                                className="profile-image-edit absolute right-0 bottom-5 text-lg cursor-pointer w-8 h-8 rounded-full grid place-items-center shadow bg-primary text-white "
                                                                      >
                                                                                <BiCamera />
                                                                      </label>
                                                            </div>
                                                  </div>
                                                  <div className="profile-details py-5 font-poppins">
                                                            <div className="flex justify-end">
                                                                      <label
                                                                                htmlFor="profile-edit-modal"
                                                                                className="edit-btn cursor-pointer md:my-4"
                                                                      >
                                                                                <div className="tooltip tooltip-left" data-tip="Edit Profile">
                                                                                          <TbEdit size={22} />
                                                                                </div>
                                                                      </label>
                                                            </div>
                                                            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
                                                                      <span className="profile-details-item-label">পুরো নাম</span>
                                                                      <span className="profile-details-item-value font-bold select-none flex items-center gap-2">
                                                                                {data?.firstName} {data?.lastName} {data?.role === 'admin' && <span className="tooltip tooltip-left" data-tip="Bogura Polytechnic Institute confirmed this profile is authentic." title="Verified account"><MdVerified color="#1C96E8" /></span>}
                                                                      </span>
                                                            </div>
                                                            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
                                                                      <span className="profile-details-item-label">ইমেইল/ইউজারনেইম</span>
                                                                      <span
                                                                                className="profile-details-item-value font-bold select-none"
                                                                      >
                                                                                {data?.email || "N/A"}
                                                                      </span>
                                                            </div>
                                                            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
                                                                      <span className="profile-details-item-label">ফোন নাম্বার</span>
                                                                      <span className="profile-details-item-value font-bold">
                                                                                {data?.phoneNumber || "N/A"}
                                                                      </span>
                                                            </div>
                                                            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
                                                                      <span className="profile-details-item-label">
                                                                                যোগদানের তারিখ
                                                                      </span>
                                                                      <span className="profile-details-item-value font-bold">
                                                                                {dateDistance}
                                                                      </span>
                                                            </div>
                                                            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
                                                                      <span className="profile-details-item-label">স্ট্যাটাস</span>
                                                                      <span className="profile-details-item-value font-bold">
                                                                                <span className="badge badge-info text-white">Active</span>
                                                                      </span>
                                                            </div>
                                                            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
                                                                      <span className="profile-details-item-label">
                                                                                সর্বশেষ লগইন
                                                                      </span>
                                                                      <span className="profile-details-item-value font-bold">
                                                                                <span className="badge badge-ghost whitespace-pre-wrap">
                                                                                          {result}
                                                                                </span>
                                                                      </span>
                                                            </div>
                                                  </div>
                                        </div>
                              </div>

                              <ProfileModal refetch={refetch} />
                              <ImageChangeModal refetch={refetch} />
                    </>
          );
};

export default Profile;