import React from 'react'
import GlobalStyle from '../../../../shared/GlobalStyle/GlobalStyle'
import useTitle from '../../../../hooks/useTitle'
import { technologies } from '../../../../configs/globalData';
import { useNavigate } from 'react-router-dom';

export default function Teachers() {
          useTitle('শিক্ষক / কর্মকর্তাগণের তালিকা');

          const navigate = useNavigate();

          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                  <h1 className='pl-4'>শিক্ষক / কর্মকর্তাগণের তালিকা</h1>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-3 gap-10 mt-5'>
                                                  {
                                                            technologies.map(technology => (
                                                                      <div key={technology.id} className='flex flex-col justify-center items-center gap-5 md:cursor-pointer hover:bg-primary hover:text-white duration-300 shadow-md rounded-md p-5 mb-5'
                                                                                onClick={() => navigate(`/teachers/${technology.category}`)}
                                                                      >
                                                                                <img src={technology.image} alt={technology.name} className='w-1/2' />
                                                                                <h1 className='text-lg font-bold'>{technology.name}</h1>
                                                                      </div>
                                                            ))
                                                  }
                                        </div>
                                        <p className='pt-10 text-sm'>সর্বশেষ হাল-নাগাদঃ 13 Oct 2023</p>
                              </div>
                    </GlobalStyle>
          )
}
