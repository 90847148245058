import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import AuthReducer from "../features/AuthSlice";
import { authApi } from "../services/AuthApi";
import { studentApi } from "../services/StudentApi";
import { noticeApi } from "../services/NoticeApi";

const store = configureStore({
          reducer: {
                    auth: AuthReducer,
                    [authApi.reducerPath]: authApi.reducer,
                    [studentApi.reducerPath]: studentApi.reducer,
                    [noticeApi.reducerPath]: noticeApi.reducer,
          },
          middleware: (getDefaultMiddleware) =>
                    getDefaultMiddleware().concat([
                              authApi.middleware,
                              studentApi.middleware,
                              noticeApi.middleware,
                    ]),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;
export default store;
setupListeners(store.dispatch);