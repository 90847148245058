import React from 'react'
import GlobalStyle from '../../../shared/GlobalStyle/GlobalStyle'

export default function ComputerTeachers() {
          return (
                    <GlobalStyle>
                              <h1>Computer Teachers</h1>
                    </GlobalStyle>
          )
}
