import React, { useEffect } from 'react'
import DashboardTitle from '../../../components/DashboardTitle'
import { Link } from 'react-router-dom'
import { useDeleteStudentMutation, useGetAllStudentsQuery } from '../../../services/StudentApi';
import useTitle from '../../../hooks/useTitle';
import { IoMdTrash } from 'react-icons/io';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import Loader from '../../../components/Loader';

export default function AllStudents() {
          useTitle('সকল শিক্ষার্থীর তালিকা');

          const { data, isLoading, refetch } = useGetAllStudentsQuery({});

          const [deleteStudent, { data: deleteData, error }] = useDeleteStudentMutation();

          const students = data?.data;

          const handleDelete = (id: string) => {
                    Swal.fire({
                              title: 'আপনি কি নিশ্চিত?',
                              text: "আপনি এই শিক্ষার্থীকে ডিলিট করতে চান?",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'হ্যাঁ, ডিলিট করুন',
                              cancelButtonText: 'না, ডিলিট করব না'
                    }).then(async (result) => {
                              if (result.isConfirmed) {
                                        await deleteStudent({ id } as any);
                              }
                    })
          }

          useEffect(() => {
                    if (deleteData) {
                              toast.success('শিক্ষার্থী ডিলিট হয়েছে');
                              refetch();
                    }

                    if (error) {
                              toast.error('কিছু ভুল হচ্ছে');
                    }
          }, [deleteData, error, refetch]);

          return (
                    <>
                              {
                                        isLoading ? <Loader /> : (
                                                  <DashboardTitle title="সকল শিক্ষার্থী" subtitle="এখানে সকল শিক্ষার্থীর তালিকা দেখতে পারবেন">
                                                            <div>
                                                                      <div className='flex justify-center md:justify-end items-center mb-10'>
                                                                                <div className="title">
                                                                                          <Link to={'/auth/dashboard/all-students/add-new'} className={`btn btn-sm md:btn-md bg-primary hover:bg-primary rounded-xl text-white`}>
                                                                                                    নতুন শিক্ষার্থী যুক্ত করুন
                                                                                          </Link>
                                                                                </div>
                                                                      </div>

                                                                      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
                                                                                {
                                                                                          students?.map((student: any) => (
                                                                                                    <div className="card w-full bg-gradient-to-bl from-[#fdf2e3] to-[#ecfdf3] border border-[#fdf2e3] shadow-md relative" key={student?._id}>
                                                                                                              <div className='flex justify-end mt-1 absolute top-2 right-2'>
                                                                                                                        <IoMdTrash className='cursor-pointer bg-error text-white rounded-lg p-1'
                                                                                                                                  onClick={() => handleDelete(student?._id)}
                                                                                                                                  size={23}
                                                                                                                        />
                                                                                                              </div>
                                                                                                              <div className="card-body">
                                                                                                                        <div className="avatar flex justify-center mb-5">
                                                                                                                                  <div className="w-32 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                                                                                                                            <img src={student?.image} draggable={false} alt={student?.fullName} />
                                                                                                                                  </div>
                                                                                                                        </div>
                                                                                                                        <p className='capitalize'><span className='font-bold'>নামঃ </span>{student?.fullName}</p>
                                                                                                                        <p><span className='font-bold'>বোর্ড রোলঃ </span>{student?.boardRoll}</p>
                                                                                                                        <p className='capitalize'><span className='font-bold'>টেকনোলজিঃ </span>{student?.technology} Technology</p>
                                                                                                                        <p><span className='font-bold'>সেশনঃ </span>{student?.session}</p>
                                                                                                                        <div className='flex items-center justify-center gap-2'>
                                                                                                                                  <Link to={`/auth/dashboard/all-students/${student?._id}`} className='text-white flex justify-center items-center'>
                                                                                                                                            <button className='btn btn-xs md:btn-sm bg-primary hover:bg-primary rounded-md text-white mt-5'>
                                                                                                                                                      বিস্তারিত দেখুন
                                                                                                                                            </button>
                                                                                                                                  </Link>
                                                                                                                                  <Link to={`/auth/dashboard/all-students/id-card/${student?._id}`} className='text-white flex justify-center items-center'>
                                                                                                                                            <button className='btn btn-xs md:btn-sm bg-primary hover:bg-primary rounded-md text-white mt-5'>
                                                                                                                                                      আইডি কার্ড
                                                                                                                                            </button>
                                                                                                                                  </Link>
                                                                                                                        </div>
                                                                                                              </div>
                                                                                                    </div>
                                                                                          ))
                                                                                }
                                                                      </div>
                                                            </div>
                                                  </DashboardTitle>
                                        )
                              }
                    </>
          )
}
