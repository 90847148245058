import React from 'react'
import DashboardTitle from '../../../components/DashboardTitle'
import useTitle from '../../../hooks/useTitle'
import { Link, useNavigate } from 'react-router-dom';
import { useGetAllNoticesQuery } from '../../../services/NoticeApi';
import moment from 'moment';
import Loader from '../../../components/Loader';

export default function AllNotices() {
          useTitle('নোটিশ সমূহ');

          const navigate = useNavigate();

          const { data, isLoading } = useGetAllNoticesQuery({});

          const notices = data?.notices;

          return (
                    <>
                              {
                                        isLoading ? <Loader /> : (
                                                  <DashboardTitle title='নোটিশ সমূহ' subtitle='এখানে সকল নোটিশ সমূহ দেখতে পারবেন'>
                                                            <div>
                                                                      <div className='flex justify-center md:justify-end items-center mb-10'>
                                                                                <div className="title">
                                                                                          <Link to={'/auth/dashboard/all-notices/add-new'} className={`btn btn-sm md:btn-md bg-primary hover:bg-primary rounded-xl text-white`}>
                                                                                                    নতুন নোটিশ যুক্ত করুন
                                                                                          </Link>
                                                                                </div>
                                                                      </div>
                                                                      <div className="overflow-x-auto">
                                                                                <table className="min-w-full divide-y divide-black border border-[#464242]">
                                                                                          <thead>
                                                                                                    <tr>
                                                                                                              <th className="md:px-6 py-6 text-center md:text-left text-base leading-4 font-semibold uppercase tracking-wider w-1/3">
                                                                                                                        তারিখ
                                                                                                              </th>
                                                                                                              <th className="md:px-6 py-6 text-center md:text-left text-base leading-4 font-semibold uppercase tracking-wider w-2/3">
                                                                                                                        শিরোনাম
                                                                                                              </th>
                                                                                                    </tr>
                                                                                          </thead>
                                                                                          <tbody>
                                                                                                    {
                                                                                                              notices.map((notice: any) => (
                                                                                                                        <tr key={notice._id} className='border border-[#464242]'>
                                                                                                                                  <td className="px-2 md:px-6 py-6 whitespace-no-wrap flex flex-col cursor-pointer text-sm md:text-base border-r border-r-[#464242]" onClick={() => navigate(`/auth/dashboard/all-notices/${notice._id}`)}>
                                                                                                                                            <span>{moment(notice.createdAt).format('DD MMM YYYY, hh:mm:ss A')}</span>
                                                                                                                                            <span className='font-bold'>Views: {notice.views}</span>
                                                                                                                                  </td>
                                                                                                                                  <td className='md:hover:underline md:hover:text-primary px-2 md:px-6 text-sm md:text-base text-start cursor-pointer duration-300' onClick={() => navigate(`/auth/dashboard/all-notices/${notice._id}`)}>{notice.title}</td>
                                                                                                                        </tr>
                                                                                                              ))
                                                                                                    }
                                                                                          </tbody>
                                                                                </table>
                                                                      </div>
                                                            </div>
                                                  </DashboardTitle>
                                        )
                              }
                    </>
          )
}
