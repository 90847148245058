import {
          BaseQueryFn,
          createApi,
          FetchArgs,
          fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import Cookies from "universal-cookie";
import { BASE_API } from "../configs/config";
const cookies = new Cookies();

type StudentInfoType = {
          _id?: number;
          fullName: string;
          boardRoll: string;
          admissionRoll: string;
          registrationNumber: string;
          technology: string;
          session: string;
          shift: string;
          bloodGroup?: string;
          cloudinaryId?: string;
          createdAt?: string;
          updatedAt?: string;
};

// type paginationType = {
//           page: number;
//           limit: number;
// };

interface CustomError {
          data: {
                    message: string;
                    success: boolean;
          };
          status: number;
}

export const studentApi = createApi({
          reducerPath: "studentApi",
          baseQuery: fetchBaseQuery({
                    baseUrl: BASE_API,
                    prepareHeaders: (headers, api) => {
                              const cookie = cookies.get("user");
                              const token = cookie?.token;
                              if (token) {
                                        headers.set("authorization", `Bearer ${token}`);
                              }
                              return headers;
                    },
          }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
          tagTypes: ["GetStudentInfo"],
          endpoints: (builder) => ({
                    getAllStudents: builder.query({
                              query: () => "/students",
                              providesTags: ["GetStudentInfo"],
                    }),
                    getStudentById: builder.query({
                              query: ({ id }) => `/students/by-id?id=${id}`,
                              providesTags: ["GetStudentInfo"],
                    }),
                    createStudent: builder.mutation<StudentInfoType, StudentInfoType>({
                              query: (body) => ({
                                        url: `/students/create-student`,
                                        method: "POST",
                                        body,
                              }),
                              invalidatesTags: ["GetStudentInfo"],
                    }),
                    deleteStudent: builder.mutation({
                              query: (body) => ({
                                        url: `/students/delete-student`,
                                        method: "DELETE",
                                        body,
                              }),
                              invalidatesTags: ["GetStudentInfo"],
                    }),
          }),
});

export const {
          useGetAllStudentsQuery,
          useGetStudentByIdQuery,
          useCreateStudentMutation,
          useDeleteStudentMutation,
} = studentApi;