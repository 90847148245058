import React from 'react'
import { Link } from 'react-router-dom';
import { links } from './MyLinks';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

type Props = {
          heading?: string,
          setHeading?: any,
          subHeading?: string,
          setSubHeading?: any,
          setIsMobileMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function NavLinks({ heading, setHeading, subHeading, setSubHeading, setIsMobileMenuOpen }: Props) {
          return (
                    <>
                              {links.map((link, index) => (
                                        <div key={index}>
                                                  <div className="px-3 text-left md:cursor-pointer group">
                                                            {
                                                                      link.subMenu ? (
                                                                                <>
                                                                                          <h1 className='py-2 lg:py-7 hidden lg:flex justify-between items-center lg:pr-0 pr-5 group select-none font-semibold md:cursor-pointer'
                                                                                          >
                                                                                                    {link.name}
                                                                                                    {link.subMenu && (
                                                                                                              <span className='lg:hidden inline'>
                                                                                                                        {heading === link.name ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
                                                                                                              </span>
                                                                                                    )}
                                                                                                    {link.subMenu && (
                                                                                                              <span className='lg:-mt-1 lg:ml-1 lg:block hidden group-hover:rotate-180 group-hover:-mt-1 duration-300'>
                                                                                                                        <BiChevronDown size={20} />
                                                                                                              </span>
                                                                                                    )}
                                                                                          </h1>
                                                                                          <h1 className='py-2 lg:py-7 lg:hidden flex justify-between items-center lg:pr-0 pr-5 group select-none font-semibold'
                                                                                                    onClick={() => {
                                                                                                              heading !== link.name ? setHeading(link.name) : setHeading('')
                                                                                                              setSubHeading('');
                                                                                                    }}>
                                                                                                    {link.name}
                                                                                                    {link.subMenu && (
                                                                                                              <span className='lg:hidden inline'>
                                                                                                                        {heading === link.name ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
                                                                                                              </span>
                                                                                                    )}
                                                                                                    {link.subMenu && (
                                                                                                              <span className='lg:-mt-1 lg:ml-2 lg:block hidden group-hover:rotate-180 group-hover:-mt-1 duration-300'>
                                                                                                                        <BiChevronDown size={20} />
                                                                                                              </span>
                                                                                                    )}
                                                                                          </h1>
                                                                                </>
                                                                      ) : (
                                                                                <Link
                                                                                          to={link.link ? link.link : '/about-us'}
                                                                                          onClick={window.innerWidth <= 640 ? () => setIsMobileMenuOpen && setIsMobileMenuOpen(false) : () => { }}
                                                                                          className='py-2 lg:py-7 flex justify-between items-center lg:pr-0 pr-5 select-none font-semibold'>
                                                                                          {link.name}
                                                                                </Link>
                                                                      )
                                                            }
                                                            {
                                                                      link.subMenu && (
                                                                                <div className='relative'>
                                                                                          <div className='absolute -top-3 hidden group-hover:lg:block hover:lg:block'>
                                                                                                    <div
                                                                                                              className='w-6 h-6 left-4 absolute -mt-3 bg-white rotate-45 border-t-2 border-l-2 rounded-tl-md'
                                                                                                    ></div>
                                                                                                    <div className={`bg-white grid
                                                                                                    ${link.subLinks?.length === 1 ? 'w-60 grid-cols-1 gap-10' : link.subLinks?.length === 2 ? 'w-72 gap-10' : 'w-[33rem] grid-cols-3 gap-0'}
                                                                                                     py-3 px-3 rounded-lg border-2`}>
                                                                                                              {link.subLinks?.map((subLink, index) => (
                                                                                                                        <div key={index} className={`w-full ${subLink.head && 'pt-3'}`}>
                                                                                                                                  {
                                                                                                                                            subLink.head && (
                                                                                                                                                      <h1 className='text-base font-semibold'>
                                                                                                                                                                {subLink.head}
                                                                                                                                                      </h1>
                                                                                                                                            )
                                                                                                                                  }
                                                                                                                                  {
                                                                                                                                            subLink.subLink.map((sub, index) => (
                                                                                                                                                      <li key={index} className='text-sm text-gray-600 my-2.5'>
                                                                                                                                                                <Link to={sub.link} className='hover:text-primary'>{sub.name}</Link>
                                                                                                                                                      </li>
                                                                                                                                            ))
                                                                                                                                  }
                                                                                                                        </div>
                                                                                                              ))}
                                                                                                    </div>
                                                                                          </div>
                                                                                </div>
                                                                      )
                                                            }
                                                  </div>

                                                  <div className={`
                                                            ${heading === link.name ? 'lg:hidden' : 'hidden'}
                                                  `}>
                                                            {
                                                                      link.subLinks?.map((subLink, index) => (
                                                                                <div key={index}>
                                                                                          <div>
                                                                                                    {
                                                                                                              subLink.head && (
                                                                                                                        <h1
                                                                                                                                  onClick={() =>
                                                                                                                                            subHeading !== subLink.head ? setSubHeading(subLink.head) : setSubHeading('')
                                                                                                                                  }
                                                                                                                                  className='py-2 lg:py-4 pl-7 font-semibold flex justify-between items-center md:cursor-pointer lg:cursor-default'>
                                                                                                                                  {subLink.head}

                                                                                                                                  <span className='lg:mt-1 lg:ml-2 inline pr-4 lg:pr-0'>
                                                                                                                                            {subHeading === subLink.head ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
                                                                                                                                  </span>
                                                                                                                        </h1>
                                                                                                              )
                                                                                                    }
                                                                                                    <div
                                                                                                              className={`
                                                                                                              ${subHeading === subLink.head ? 'lg:hidden' : 'hidden'}
                                                                                                              `}
                                                                                                    >
                                                                                                              {subLink.subLink.map((sub, index) => (
                                                                                                                        <li key={index} className={`py-1 lg:py-2 ${subLink.head ? 'pl-10' : 'pl-7'}`}>
                                                                                                                                  <Link
                                                                                                                                            to={sub.link}
                                                                                                                                            onClick={() => {
                                                                                                                                                      setIsMobileMenuOpen && setIsMobileMenuOpen(false);
                                                                                                                                                      setHeading('');
                                                                                                                                            }}
                                                                                                                                            className='lg:hover:text-primary'>{sub.name}</Link>
                                                                                                                        </li>
                                                                                                              ))}
                                                                                                    </div>
                                                                                          </div>
                                                                                </div>
                                                                      ))
                                                            }
                                                  </div>
                                        </div>
                              ))}
                    </>
          )
}
