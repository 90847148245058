import React, { useEffect, useState } from 'react'
import { bannerSlides } from '../../configs/globalData';
import Logo from '../../assets/logo.png';

export default function BannerCarousel() {
          const [bannerCurrentIndex, setBannerCurrentIndex] = useState<number>(0);

          const bannerNextSlide = () => {
                    const isLastSlide = bannerCurrentIndex === bannerSlides?.length - 1;
                    const newIndex = isLastSlide ? 0 : bannerCurrentIndex + 1;
                    setBannerCurrentIndex(newIndex);
          }

          useEffect(() => {
                    const bannerInterval = setInterval(() => {
                              const isLastSlide = bannerCurrentIndex === bannerSlides?.length - 1;
                              const newIndex = isLastSlide ? 0 : bannerCurrentIndex + 1;
                              setBannerCurrentIndex(newIndex);
                    }, 5000);
                    return () => clearInterval(bannerInterval);
          }, [bannerCurrentIndex]);

          return (
                    <div className="relative lg:w-2/3 mx-auto">
                              <div className='h-[170px] sm:h-[200px] md:h-[230px] lg:h-[260px] xl:h-[290px] w-full mx-auto'>
                                        <div
                                                  style={{ backgroundImage: `url(${bannerSlides[bannerCurrentIndex]?.url})` }}
                                                  className='w-full h-full bg-center bg-cover duration-500 transform'
                                        ></div>
                                        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-l from-[#ffffff00] to-black opacity-50 transition-opacity duration-500 ease-in-out"></div>
                              </div>
                              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center md:justify-start md:pl-5 lg:pl-10">
                                        <div className="flex items-center gap-3 p-4">
                                                  <div className='md:cursor-pointer' onClick={bannerNextSlide}><img src={Logo} draggable={false} className='w-20' alt="Bogura Polytechnic Institute" /></div>
                                                  <h2 className="text-2xl md:text-3xl lg:text-4xl text-white font-semibold text-start" title='Bogura Polytechnic Institute'>বগুড়া পলিটেকনিক ইন্সটিটিউট</h2>
                                        </div>
                              </div>
                    </div>
          )
}
