import React from 'react'
import GlobalStyle from '../../../../shared/GlobalStyle/GlobalStyle';
import useTitle from '../../../../hooks/useTitle';
import Principal from '../../../../assets/person/principal2.jpg';

export default function VicePrincipal() {
          useTitle('উপাধ্যক্ষ');
          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                  <h1 className='pl-4'>উপাধ্যক্ষ</h1>
                                        </div>
                                        <div className='flex flex-col items-center gap-5 mt-5 z-0'>
                                                  <div>
                                                            <div className="w-full rounded-xl ring ring-primary ring-offset-base-100 ring-offset-2">
                                                                      <img src={Principal} draggable={false} alt='Bogura Polytechnic Institute' className='aspect-square object-cover w-full rounded-xl' />
                                                            </div>
                                                  </div>
                                                  <div className='flex flex-col justify-center items-center gap-0 md:gap-1 lg:gap-2 pt-5'>
                                                            <p className='text-xl md:text-2xl lg:text-3xl font-bold'>মোঃ আবু সাইম জাহান</p>
                                                            <p className='text-base md:text-lg font-semibold'>অধ্যক্ষ (অতিরিক্ত দায়িত্ব)</p>
                                                            <p className='text-xs md:text-sm font-semibold'>বগুড়া পলিটেকনিক ইন্সটিটিউট</p>
                                                  </div>
                                        </div>
                                        <div className='flex flex-col justify-center items-center gap-0 md:gap-1 lg:gap-2 mb-5 mt-10'>
                                                  <p className='text-sm md:text-base lg:text-lg font-semibold'>মোবাইল নংঃ- <a href="tel:+8801309133819" className='md:hover:text-primary'>+8801309133819</a></p>
                                                  <p className='text-sm md:text-base lg:text-lg font-semibold'>টেলিফোন নংঃ- <a href="tel:+8802588814060" className='md:hover:text-primary'>+8802588814060</a></p>
                                                  <p className='text-xs md:text-base lg:text-lg font-semibold'>ইমেইলঃ- <a href="mailto:abusayemjahan@bogurapoly.gov.bd" className='md:hover:text-primary'>abusayemjahan@bogurapoly.gov.bd</a></p>
                                        </div>
                              </div>
                    </GlobalStyle>
          )
}
