import React, { useEffect, useState } from 'react'
import useTitle from '../../../hooks/useTitle';
import { useNavigate } from 'react-router-dom';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { PulseLoader } from 'react-spinners';
import { useCreateNoticeMutation } from '../../../services/NoticeApi';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { BsFiletypePdf } from 'react-icons/bs';
import { BiPen } from 'react-icons/bi';

import { Viewer, ProgressBar, SpecialZoomLevel } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import { IoMdTrash } from 'react-icons/io';

const Fade = require("react-reveal/Fade");

export default function AddNewNotice() {
          useTitle('নতুন নোটিশ যুক্ত করুন');

          const navigate = useNavigate();

          const [previewSource, setPreviewSource] = useState<string>("");

          const [createNotice, { data, isLoading, isSuccess, error }] =
                    useCreateNoticeMutation();

          const { register, handleSubmit, reset, watch } = useForm();

          const onSubmit = async (data: any) => {
                    const pdf = data.pdf[0];
                    const formData = new FormData() as any;
                    formData.append('title', data.title);
                    formData.append('pdf', pdf);
                    await createNotice(formData);
          };

          watch((data) => {
                    if (data?.pdf?.length > 0) {
                              setPreviewSource(URL.createObjectURL(data?.pdf[0]));
                    }
          });

          useEffect(() => {
                    if (error) {
                              toast.error((error as any).data?.message);
                    }

                    if (isSuccess) {
                              toast.success((data as any).message);
                              reset();
                    }
          }, [data, isLoading, isSuccess, error, reset]);

          return (
                    <div className="p-2 bg-base-100 rounded-md pb-12">
                              <div className="title my-2 mb-5">
                                        <h3 className="text-xl font-semibold flex items-center gap-2"><HiOutlineArrowNarrowLeft size={20} className='cursor-pointer' onClick={() => navigate(-1)} /> নতুন নোটিশ যুক্ত করুন</h3>
                                        <span className='text-sm'>এখানে নতুন নোটিশ যুক্ত করতে পারবেন</span>
                              </div>
                              <Fade top distance="20px">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                  <div className="name border rounded p-3 relative mt-10">
                                                            <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                      <h3 className="text-xs font-poppins">পিডিএফ</h3>
                                                            </div>
                                                            <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2"}`}>
                                                                      <div className="icon">
                                                                                <BsFiletypePdf />
                                                                      </div>
                                                                      <input
                                                                                type="file"
                                                                                accept='.pdf'
                                                                                className='file-input file-input-ghost focus:outline-none form-control outline-none border-none pl-4 w-full'
                                                                                {...register('pdf')}
                                                                      />
                                                            </div>
                                                  </div>
                                                  {
                                                            previewSource && (
                                                                      <>
                                                                                <div className='mt-5 h-[80vh] border-2'>
                                                                                          <Viewer
                                                                                                    renderLoader={(percentages: number) => (
                                                                                                              <div style={{ width: '240px' }}>
                                                                                                                        <ProgressBar progress={Math.round(percentages)} />
                                                                                                              </div>
                                                                                                    )}
                                                                                                    defaultScale={window.innerWidth < 640 ? 1.2 : SpecialZoomLevel.PageWidth}
                                                                                                    fileUrl={previewSource}
                                                                                          />
                                                                                </div>
                                                                                <div className='flex justify-end mt-2'>
                                                                                          <IoMdTrash className='cursor-pointer bg-error text-white rounded-lg p-1'
                                                                                                    onClick={() => {
                                                                                                              setPreviewSource('')
                                                                                                              reset({
                                                                                                                        pdf: null
                                                                                                              })
                                                                                                    }}
                                                                                                    size={23}
                                                                                          />
                                                                                </div>
                                                                      </>
                                                            )
                                                  }
                                                  <div className="name border rounded p-3 relative mt-10">
                                                            <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                      <h3 className="text-xs font-poppins">টাইটেল</h3>
                                                            </div>
                                                            <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                      <div className="icon">
                                                                                <BiPen />
                                                                      </div>
                                                                      <input
                                                                                type="text"
                                                                                className="form-control outline-none pl-4 w-full bg-transparent"
                                                                                placeholder="টাইটেল"
                                                                                {...register('title')}
                                                                      />
                                                            </div>
                                                  </div>
                                                  <div className='mt-10 flex justify-center md:justify-end'>
                                                            {isLoading ? (
                                                                      <button className="btn btn-sm md:btn-md bg-primary hover:bg-primary border-none" type="button">
                                                                                <PulseLoader size={8} color="#fff" />
                                                                      </button>
                                                            ) : (
                                                                      <button disabled={isLoading} className="btn btn-sm md:btn-md bg-primary hover:bg-primary border-none text-white" type="submit">
                                                                                সাবমিট করুন
                                                                      </button>
                                                            )}
                                                  </div>
                                        </form>
                              </Fade>
                    </div>
          )
}
