import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useGetStudentByIdQuery } from '../../../services/StudentApi';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import moment from 'moment';
import Loader from '../../../components/Loader';

const Fade = require("react-reveal/Fade");

export default function SingleStudent() {
          const { id } = useParams<{ id: string }>();

          const navigate = useNavigate();

          const { data, isLoading } = useGetStudentByIdQuery({ id });

          const student = data?.data;

          return (
                    <>
                              {
                                        isLoading ? <Loader /> : (
                                                  <div className="p-2 bg-base-100 rounded-md pb-12">
                                                            <div className="title my-2 mb-5">
                                                                      <h3 className="text-xl font-semibold flex items-center gap-2"><HiOutlineArrowNarrowLeft size={20} className='cursor-pointer' onClick={() => navigate(-1)} /> শিক্ষার্থীর তথ্য</h3>
                                                                      <span className='text-sm'>এখানে শিক্ষার্থীর তথ্য দেখতে পারবেন</span>
                                                            </div>
                                                            <Fade top distance="20px">
                                                                      <div className="card w-full bg-gradient-to-bl from-[#fdf2e3] to-[#ecfdf3] border border-[#fdf2e3] shadow-md">
                                                                                <div className="card-body flex flex-col md:flex-row justify-around items-center gap-5 md:gap-0">
                                                                                          <div className="avatar flex justify-center mb-5 w-full">
                                                                                                    <div className="w-full md:w-72 rounded-md ring ring-primary ring-offset-base-100 ring-offset-2">
                                                                                                              <img src={student?.image} draggable={false} alt={student?.fullName} />
                                                                                                    </div>
                                                                                          </div>
                                                                                          <div className='w-full text-lg md:text-xl flex flex-col justify-center gap-2'>
                                                                                                    <p className='capitalize'><span className='font-bold'>নামঃ </span>{student?.fullName}</p>
                                                                                                    <p><span className='font-bold'>এডমিশন রোলঃ </span>{student?.admissionRoll}</p>
                                                                                                    <p><span className='font-bold'>বোর্ড রোলঃ </span>{student?.boardRoll}</p>
                                                                                                    <p><span className='font-bold'>রেজিস্ট্রেশন নাম্বারঃ </span>{student?.registrationNumber}</p>
                                                                                                    <p className='capitalize'><span className='font-bold'>শিফটঃ </span>{student?.shift}</p>
                                                                                                    <p className='capitalize'><span className='font-bold'>টেকনোলোজিঃ </span>{student?.technology} Technology</p>
                                                                                                    <p><span className='font-bold'>সেশনঃ </span>{student?.session}</p>
                                                                                                    <p><span className='font-bold'>রক্তের গ্রুপঃ </span>{student?.bloodGroup ? student?.bloodGroup : 'N/A'}</p>
                                                                                          </div>
                                                                                </div>
                                                                                <div className='pb-10 px-10 md:px-0 text-sm text-center'>সর্বশেষ হাল-নাগাদঃ {moment(student?.updatedAt).format('MMMM Do YYYY, h:mm:ss A')}</div>
                                                                      </div>
                                                            </Fade>
                                                  </div>
                                        )
                              }
                    </>
          )
}
