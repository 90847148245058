import React from 'react'
import GlobalStyle from '../../shared/GlobalStyle/GlobalStyle'
import { GrLocation } from 'react-icons/gr'
import { AiOutlinePhone } from 'react-icons/ai'
import { HiMail } from 'react-icons/hi'
import useTitle from '../../hooks/useTitle'
import useScrollToTop from '../../hooks/useScrollToTop'

export default function ContactUs() {
          useTitle('যোগাযোগ');
          useScrollToTop();

          return (
                    <GlobalStyle>
                              <div className='my-5 md:my-0 px-2 lg:px-0'>
                                        <div className="border-l-4 border-red-500 bg-primary text-white py-3">
                                                  <h1 className='pl-4'>যোগাযোগ</h1>
                                        </div>
                                        <div className='flex flex-col justify-center text-center pt-4'>
                                                  <h3 className='text-lg font-bold pt-2' title='Bogura Polytechnic Institute'>বগুড়া পলিটেকনিক ইন্সটিটিউট</h3>
                                                  <p className='text-sm pb-2'>শেরপুর রোড, বগুড়া - ৫৮০০</p>
                                                  <p className='text-sm pt-5 px-4 md:px-24 lg:px-28 font-semibold'>আপনি কি কিছু জানতে চান ? আমাদের সাথে সরাসরি যোগাযোগ করতে দ্বিধাবোধ করবেন না দয়া করে। আমাদের দল আপনাকে সাহায্য করার জন্য কয়েক ঘন্টার মধ্যে আপনাকে ফিরতি বার্তা পাঠাবে ।</p>
                                        </div>
                                        <div className='pt-16 md:px-16 flex flex-col md:flex-row justify-between items-center gap-5'>
                                                  <div className='flex flex-col justify-center items-center gap-2'>
                                                            <GrLocation className='inline-block' size={30} />
                                                            <p className='text-sm'>শেরপুর রোড, বগুড়া - ৫৮০০</p>
                                                  </div>
                                                  <div className='flex flex-col justify-center items-center gap-2'>
                                                            <AiOutlinePhone className='inline-block' size={30} />
                                                            <p className='text-sm'><a href="tel:+8801309133819">+8801309133819</a></p>
                                                  </div>
                                                  <div className='flex flex-col justify-center items-center gap-2'>
                                                            <HiMail className='inline-block' size={30} />
                                                            <p className='text-sm'><a href="mailto:contact@bogurapoly.gov.bd">contact@bogurapoly.gov.bd</a></p>
                                                  </div>
                                        </div>
                                        <div className='pt-16 w-full'>
                                                  <iframe
                                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226.324571546604!2d89.37756077667181!3d24.823067628052172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fc54968d3b5cdd%3A0xd5c89978703585d6!2sBogura%20Polytechnic%20Institute!5e0!3m2!1sen!2sbd!4v1699209608115!5m2!1sen!2sbd"
                                                            width="100%"
                                                            title='Bogura Polytechnic Institute'
                                                            loading="lazy"
                                                            className='h-96 rounded-lg border border-gray-300 shadow-md'
                                                  ></iframe>
                                        </div>
                              </div>
                    </GlobalStyle>
          )
}
