import React from 'react'

type Props = {
          children: React.ReactNode,
          title: string,
          subtitle: string,
}

const Fade = require("react-reveal/Fade");

export default function DashboardTitle({ children, title, subtitle }: Props) {
          return (
                    <div className="p-2 bg-base-100 rounded-md pb-12">
                              <div className="title my-2 mb-5">
                                        <h3 className="text-xl font-semibold">{title}</h3>
                                        <span className='text-sm'>{subtitle}</span>
                              </div>
                              <Fade top distance="20px">
                                        {children}
                              </Fade>
                    </div>
          )
}
