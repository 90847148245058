export const links = [
          {
                    name: "আমাদের সম্পর্কে",
                    subMenu: true,
                    subLinks: [
                              {
                                        head: "পরিচিতি",
                                        subLink: [
                                                  {
                                                            name: "সংক্ষিপ্ত পরিচিতি",
                                                            link: "/short-intro"
                                                  },
                                                  {
                                                            name: "লক্ষ্য ও উদ্দেশ্য",
                                                            link: "/aims-and-objectives"
                                                  },
                                                  {
                                                            name: "বাণী ( অধ্যক্ষ )",
                                                            link: "/principal-message"
                                                  },
                                        ]
                              },
                              {
                                        head: "প্রধানগণ",
                                        subLink: [
                                                  {
                                                            name: "অধ্যক্ষ",
                                                            link: "/principal"
                                                  },
                                                  {
                                                            name: "সহকারী অধ্যক্ষ",
                                                            link: "/vice-principal"
                                                  },
                                                  {
                                                            name: "প্রাক্তণ অধ্যক্ষগণ",
                                                            link: "/former-headmasters"
                                                  },
                                        ]
                              },
                              {
                                        head: " কর্মকর্তা ও কর্মচারী",
                                        subLink: [
                                                  {
                                                            name: " শিক্ষক / কর্মকর্তাগণের তালিকা",
                                                            link: "/teachers"
                                                  },
                                                  {
                                                            name: "স্টাফ তালিকা",
                                                            link: "/staffs-list"
                                                  },
                                        ]
                              }
                    ],
          },
          {
                    name: "টেকনোলজি সমূহ",
                    subMenu: true,
                    subLinks: [
                              {
                                        head: "",
                                        subLink: [
                                                  {
                                                            name: " সিভিল টেকনোলজি",
                                                            link: "/teachers/civil"
                                                  },
                                                  {
                                                            name: "মেকানিক্যাল টেকনোলজি",
                                                            link: "/teachers/mechanical"
                                                  },
                                                  {
                                                            name: "পাওয়ার টেকনোলজি",
                                                            link: "/teachers/power"
                                                  },
                                                  {
                                                            name: "ইলেকট্রিক্যাল টেকনোলজি",
                                                            link: "/teachers/electrical"
                                                  },
                                                  {
                                                            name: "ইলেকট্রনিক্স টেকনোলজি",
                                                            link: "/teachers/electronics"
                                                  },
                                                  {
                                                            name: "কম্পিউটার টেকনোলজি",
                                                            link: "/teachers/computer"
                                                  },
                                                  {
                                                            name: "আর. এ. সি. টেকনোলজি",
                                                            link: "/teachers/rac"
                                                  },
                                                  {
                                                            name: "মাইনিং টেকনোলজি",
                                                            link: "/teachers/mining"
                                                  },
                                                  {
                                                            name: "ট্যুরিজম টেকনোলজি",
                                                            link: "/teachers/tourism"
                                                  },
                                                  {
                                                            name: "রিলেটেড সাবজেক্ট",
                                                            link: "/teachers/related-subjects"
                                                  },
                                        ]
                              }
                    ],
          },
          {
                    name: "একাডেমিক",
                    subMenu: true,
                    subLinks: [
                              {
                                        head: "",
                                        subLink: [
                                                  {
                                                            name: "ক্লাস রুটিন",
                                                            link: "/class-routine"
                                                  },
                                                  {
                                                            name: "পরীক্ষার রুটিন",
                                                            link: "/exam-routine"
                                                  },
                                        ]
                              }
                    ],
          },
          {
                    name: "পরীক্ষার ফলাফল",
                    subMenu: true,
                    subLinks: [
                              {
                                        head: "",
                                        subLink: [
                                                  {
                                                            name: "ফলাফল",
                                                            link: "/results"
                                                  },
                                        ]
                              }
                    ],
          },
          {
                    name: "গ্যালারি",
                    subMenu: true,
                    subLinks: [
                              {
                                        head: "",
                                        subLink: [
                                                  {
                                                            name: "ইন্সটিটিউটের বিভিন্ন কার্যক্রম",
                                                            link: "/gallery/institute"
                                                  },
                                                  {
                                                            name: "রোভার",
                                                            link: "/gallery/rover"
                                                  },
                                                  {
                                                            name: "রেড ক্রিসেন্ট",
                                                            link: "/gallery/red-crescent"
                                                  }
                                        ]
                              }
                    ],
          },
          {
                    name: "শিক্ষার্থীদের তথ্য",
                    subMenu: false,
                    link: "/student-info"
          },
          {
                    name: "যোগাযোগ",
                    subMenu: false,
                    link: "/contact-us"
          },
];