import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
          BiEnvelope,
          BiPhoneIncoming,
          BiUser,
} from "react-icons/bi";
import { PulseLoader } from "react-spinners";
import useAuth from "../../../hooks/useAuth";
import { authUserInterface } from "../../../interfaces/UserInterface";
import { useUpdateProfileMutation } from "../../../services/AuthApi";

type Props = {
          refetch: () => void;
};

const ProfileModal = ({ refetch }: Props) => {
          const { updatedUser } = useAuth<authUserInterface | any>({});
          const [updateProfile, { data, isSuccess, isLoading }] =
                    useUpdateProfileMutation();

          const { register, handleSubmit, reset, setValue } = useForm();

          const handleUpdateProfile = handleSubmit(async (formData) => {
                    if (formData?.phoneNumber?.length < 11 || formData?.phoneNumber?.length > 11) {
                              return toast.error("Phone number must be 11 digits");
                    }

                    const bodyData = { ...formData, email: updatedUser?.email };
                    await updateProfile(bodyData);
          });

          useEffect(() => {
                    setValue("firstName", updatedUser?.firstName);
                    setValue("lastName", updatedUser?.lastName);
                    setValue("phoneNumber", updatedUser?.phoneNumber);

                    if (isSuccess) {
                              toast.success((data as any).message);
                              reset();
                              refetch();
                    }
          }, [isSuccess, data, setValue, updatedUser, refetch, reset]);

          return (
                    <form onSubmit={handleUpdateProfile}>
                              <input type="checkbox" id="profile-edit-modal" className="modal-toggle" />
                              <div className={`modal modal-bottom sm:modal-middle`}>
                                        <div className="modal-box md:w-2/3 lg:w-1/3 md:max-w-5xl">
                                                  <h3 className="font-bold text-xl">Edit Profile Information</h3>
                                                  <div className="modal-body">
                                                            <div className="name border rounded p-3 relative mt-10 flex-1">
                                                                      <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">First Name</h3>
                                                                      </div>
                                                                      <div className="flex items-center gap-3">
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                                          <div className="icon">
                                                                                                    <BiUser />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="text"
                                                                                                    className={`form-control outline-none pl-4 w-full`}
                                                                                                    placeholder="First Name"
                                                                                                    defaultValue={updatedUser.firstName}
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                            <div className="name border rounded p-3 relative mt-10 flex-1">
                                                                      <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">Last Name</h3>
                                                                      </div>
                                                                      <div className="flex items-center gap-3">
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                                          <div className="icon">
                                                                                                    <BiUser />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="text"
                                                                                                    className={`form-control outline-none pl-4 w-full`}
                                                                                                    placeholder="Last Name"
                                                                                                    defaultValue={updatedUser.lastName}
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                            <div className="flex flex-col md:flex-row md:gap-2">
                                                                      <div className="name border rounded p-3 relative mt-10 flex-1">
                                                                                <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                          <h3 className="text-xs font-poppins">Email</h3>
                                                                                </div>
                                                                                <div
                                                                                          className="input-group flex items-center my-2 border p-3 rounded-md mt-2 bg-base-300 tooltip"
                                                                                          data-tip="Readonly"
                                                                                >
                                                                                          <div className="icon">
                                                                                                    <BiEnvelope />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="email"
                                                                                                    className="form-control outline-none pl-4 w-full bg-base-300"
                                                                                                    placeholder="Email"
                                                                                                    defaultValue={updatedUser.email}
                                                                                                    readOnly
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                                      <div className="name border rounded p-3 relative mt-10 flex-1">
                                                                                <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                          <h3 className="text-xs font-poppins">Phone Number</h3>
                                                                                </div>
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                                          <div className="icon">
                                                                                                    <BiPhoneIncoming />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="number"
                                                                                                    className={`form-control outline-none pl-4 w-full`}
                                                                                                    placeholder="Phone Number"
                                                                                                    defaultValue={updatedUser.phoneNumber}
                                                                                                    {...register("phoneNumber")}
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                  </div>
                                                  <div className="modal-action">
                                                            <label htmlFor="profile-edit-modal" className="btn btn-sm md:btn-md btn-warning text-white">
                                                                      Cancel
                                                            </label>
                                                            {isLoading ? (
                                                                      <button className="btn btn-sm md:btn-md btn-success" type="button">
                                                                                <PulseLoader size={8} color="#fff" />
                                                                      </button>
                                                            ) : (
                                                                      <button className="btn btn-sm md:btn-md btn-success text-white" type="submit">
                                                                                Update
                                                                      </button>
                                                            )}
                                                  </div>
                                        </div>
                              </div>
                    </form>
          );
};

export default ProfileModal;